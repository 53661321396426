import { Grid, Container } from '@mui/material';
import React, { useEffect } from 'react';
import { CategoryApi } from 'src/apis/category';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { encodeTitle } from 'src/tools/title-encoder';
import HelmetTag from 'src/components/helmet-tag';
import { useTranslation } from 'react-i18next';

export default function CategoryIndexView() {
  const [categories, setCategories] = React.useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    CategoryApi.getTopLevelCategories().then((categories) => {
      setCategories(categories);
    });
  }, [])

  const keywords = categories.map((category) => category.name).join(", ");


  return (
    <Container>
      <HelmetTag
        metaTitle={t("categories.title")}
        metaKeywords={keywords}
        metaDescription={t("categories.list") + keywords}
      />
      <Grid container spacing={3}>
        {categories.map((category) => (
          <Grid item sm={12} md={6} lg={4} key={category.id}>
            <Typography variant="h5">
              <Link color="text.secondary" href={"/category/" + encodeTitle(category.name)}>
                {category.name}
              </Link>
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ marginTop: 1 }}>
              {category.description}
            </Typography>
          </Grid>
        ))
        }
      </Grid>
    </Container>
  );
}