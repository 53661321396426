import axios from "axios"
import { getLanguage } from "src/tools/language"

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,

  headers: {
    "Content-Type": "application/json",
    "Accept-Language": getLanguage(),
  },

});


api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token")
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);


const refreshTheToken = async (data) => {
  console.log("Refreshing Token:", data)
  await api.post("/token/refresh/", data)
    .then((response) => {
      console.log("Token Refreshed:", response.data)
      const token = response.data.access;
      const refresh = response.data.refresh;
      localStorage.setItem("token", token);
      localStorage.setItem("refresh", refresh);
      return token;
    })
    .catch((err) => {
      // If there is an error refreshing the token, log out the user
      return Promise.reject(err);
    });
}

// Add a response interceptor to refresh the JWT token if it's expired
api.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    console.log("Error Response:", error.response.status)
    const originalRequest = error.config;

    // If the error is a 401 and we have a refresh token, refresh the JWT token
    if (error.response.status === 401 && localStorage.getItem("refresh")) {
      const refreshToken = localStorage.getItem("refresh");
      localStorage.removeItem("token");
      localStorage.removeItem("refresh");
      console.log("Refreshing Token", refreshToken)

      const access_token = await refreshTheToken({
        refresh: refreshToken,
      })
      // Re-run the original request that was intercepted
      originalRequest.headers.Authorization = `Bearer ${access_token}`;
      return api(originalRequest);
    }

    // Return the original error if we can't handle it
    return Promise.reject(error);
  }
);



// defining a custom error handler for all APIs
const errorHandler = (error) => {
  return Promise.reject(error)
}

// registering the custom error handler to the
// "api" axios instance
api.interceptors.response.use(undefined, (error) => {
  return errorHandler(error)
})