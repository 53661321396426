import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, DialogContentText, Autocomplete } from '@mui/material';
import { debounce } from '@mui/material/utils';
import { CategoryApi } from 'src/apis/category';
import { v4 as uuidv4 } from 'uuid';
import Chip from '@mui/material/Chip';

const NewCategoryDialog = ({ open, inputValue, onCategoryAdd, onClose }) => {
  const [value, setValue] = React.useState([]);
  const [inputCategoryValue, setInputCategoryValue] = React.useState('');

  const [name, setName] = useState(inputValue);
  const [description, setDescription] = useState('');
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setName(inputValue);
  }, [open, inputValue]);

  const handleNameChange = (event) => {
    setName(event.target.value);
  }

  const handleAdd = () => {
    onCategoryAdd(name, description, value);
  }

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        CategoryApi.search(request.input).then((response) => {
          const results = response;
          callback(results);
        });
      }, 400),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (inputCategoryValue === '') {
      setOptions([]);
      return undefined;
    }

    fetch({ input: inputCategoryValue }, (results) => {
      if (active) {

        let newOptions = value;

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        // clear duplicates
        newOptions = newOptions.filter((option, index, self) =>
          index === self.findIndex((t) => (
            t.id === option.id
          ))
        );

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputCategoryValue, fetch]);

  return (
    <Dialog open={inputValue != null} maxWidth="md" fullWidth={true} onClose={onClose}>
      <DialogTitle>Add new category</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Add a new category to this list by filling out the form below. This category will be public and visible to all users after reviewed.
        </DialogContentText>
        <TextField
          limit={120}
          label="Title"
          value={name || ''}
          onChange={handleNameChange}
          margin='dense'
          sx={{ marginTop: 4 }}
          fullWidth
          variant='outlined'
        />
        <TextField
          label="Description"
          value={description || ''}
          onChange={(event) => setDescription(event.target.value)}
          margin='dense'
          sx={{ marginTop: 2, marginBottom: 4 }}
          fullWidth
          variant='outlined'
        />
        <Autocomplete
          id="categoryInput"
          fullWidth
          multiple
          options={options}
          isOptionEqualToValue={(option, value) => option.id === value.id}

          getOptionLabel={(option) => {
            return option.name;
          }}

          value={value}

          onChange={(event, newCategories) => {
            setValue(newCategories);
          }}
          onInputChange={(event, newInputValue) => {
            setInputCategoryValue(newInputValue);
          }}
          renderInput={(params) => (
            <TextField {...params} placeholder="Pick parent categories" variant='outlined' fullWidth />
          )}
          renderOption={(props, option) => (
            <li {...props} key={uuidv4()}>
              {option.name}
            </li>
          )}
          renderTags={(tagValue, getTagProps) => {
            return tagValue.map((option, index) => (
              <Chip {...getTagProps({ index })} key={uuidv4()} label={option.name} />
            ))
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAdd} disabled={!name}>Add</Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewCategoryDialog;