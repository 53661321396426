import { api } from "src/apis/configs/axios"
import { defineCancelApiObject } from "src/apis/configs/axiosUtils"

export const ListApi = {
  save: async function (id, title, content, reason, categories, translations, cancel = false) {
    const response = await api.request({
      url: '/api/lists/',
      method: "POST",
      data: {
        id: id,
        title: title,
        content: JSON.stringify(content),
        categories: JSON.stringify(categories),
        revisionMessage: reason,
        translations: JSON.stringify(translations),
      },
      // retrieving the signal value by using the property name
      signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
    }).catch((error) => {
      return { data: { error: "error setting" } }
    });
    return response.data
  },
  get: async function (title, version = null, cancel = false) {
    const response = await api.request({
      url: `/api/lists/?title=${title}` + (version ? `&version=${version}` : ''),
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
    }).catch((error) => {
      return { data: { error: "error getting" } }
    });

    return response.data;
  },

  getMarkdown: async function (title, version, cancel = false) {
    const response = await api.request({
      url: `/api/lists/markdown/?title=${title}&version=${version}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel ? cancelApiObject[this.getMarkdown.name].handleRequestCancellation().signal : undefined,
    }).catch((error) => {
      return { data: { error: "error getting markdown" } }
    });
    return response.data;
  },

  getHomepage: async function (cancel = false) {
    const response = await api.request({
      url: '/api/lists/get_homepage_list/',
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel ? cancelApiObject[this.getHomepage.name].handleRequestCancellation().signal : undefined,
    }).catch((error) => {
      return { data: { error: "error getting homepage" } }
    });

    return response.data;
  },

  search: async function (query, listOnly, allLanguages = false, cancel = false) {
    const response = await api.request({
      url: `/api/lists/search/?query=${query}` + (listOnly ? `&list_only=${listOnly}` : '') + (allLanguages ? `&all_languages=${allLanguages}` : ''),
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel ? cancelApiObject[this.search.name].handleRequestCancellation().signal : undefined,
    }).catch((error) => {
      return { data: { error: "error searching" } }
    })

    return response.data;
  },

  publish: async function (id, version, cancel = false) {
    const response = await api.request({
      url: `/api/lists/publish/?id=${id}&version=${version}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel ? cancelApiObject[this.publish.name].handleRequestCancellation().signal : undefined,
    }).catch((error) => {
      return { data: { error: error.response.data.message } }
    }
    );

    return response.data;
  },


  getAll: async function (cancel = false) {
    const response = await api.request({
      url: '/api/lists/',
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal : undefined,
    }).catch((error) => {
      return { data: { error: "error getting all" } }
    });

    return response.data;
  },

  getFrontpage: async function (cancel = false) {
    const response = await api.request({
      url: `/api/lists/?frontpage`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel ? cancelApiObject[this.getListsByCategory.name].handleRequestCancellation().signal : undefined,
    }).catch((error) => {
      return { data: { error: "error getting lists by category" } }
    });

    return response.data;
  },


  getListsByCategory: async function (category, onlyReviewed = true, cancel = false) {
    const response = await api.request({
      url: `/api/lists/?category=${category}&only_reviewed=${onlyReviewed}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel ? cancelApiObject[this.getListsByCategory.name].handleRequestCancellation().signal : undefined,
    }).catch((error) => {
      return { data: { error: "error getting lists by category" } }
    });

    return response.data;
  },

  getComments: async function (list_title, commentId, cancel = false) {

    const response = await api.request({
      url: `/api/comments/?list_title=${list_title}${commentId ? `&comment_id=${commentId}` : ''}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel ? cancelApiObject[this.getComments.name].handleRequestCancellation().signal : undefined,
    }).catch((error) => {
      return { data: { error: "error getting comments" } }
    });

    return response.data;
  },

  getVersions: async function (list_title, cancel = false) {
    const response = await api.request({
      url: `/api/versions/?list_title=${list_title}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel ? cancelApiObject[this.getVersions.name].handleRequestCancellation().signal : undefined,
    }).catch((error) => {
      return { data: { error: "error getting versions" } }
    });

    return response.data;
  },

  fetchUrlTitle: async function (url, cancel = false) {
    const response = await api.request({
      url: `/api/lists/fetch_url_title/?url=${url}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel ? cancelApiObject[this.fetchUrlTitle.name].handleRequestCancellation().signal : undefined,
    }).catch((error) => {
      return { data: { error: "error fetching url title" } }
    });

    return response.data;
  }

}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(ListApi)