import { Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';

export const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(215, 215, 215, 1)',
    color: 'black',
    maxWidth: 300,
    borderRadius: 5,
    border: '1px solid #dadde9',
    fontSize: theme.typography.pxToRem(12),
  },
}));