import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, DialogContentText, Autocomplete } from '@mui/material';
import { debounce } from '@mui/material/utils';
import { CategoryApi } from 'src/apis/category';
import { v4 as uuidv4 } from 'uuid';
import Chip from '@mui/material/Chip';

const AddSubCategoryDialog = ({ open, onCreate, onClose }) => {
  const [value, setValue] = React.useState(null);
  const [inputCategoryValue, setInputCategoryValue] = React.useState('');

  const [description, setDescription] = useState('');
  const [options, setOptions] = useState([]);

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        CategoryApi.search(request.input).then((response) => {
          const results = response;
          callback(results);
        });
      }, 400),
    [],
  );

  useEffect(() => {
    let active = true;

    if (inputCategoryValue === '') {
      setOptions([]);
      return undefined;
    }

    fetch({ input: inputCategoryValue }, (results) => {
      if (active) {

        setOptions(results);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputCategoryValue, fetch]);

  const handleCreate = () => {
    if (value?.id) {
      onCreate(value);
    } else {
      onCreate({
        name: value ? value.name : inputCategoryValue,
        description: description
      });
    }
  }

  return (
    <Dialog open={open} maxWidth="md" fullWidth={true} onClose={onClose}>
      <DialogTitle>Create new sub-category</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Add a new sub-category to the current category. You can also link an existing category by selecting it from the list.
        </DialogContentText>
        <Autocomplete
          sx={{ mt: 2 }}
          id="categoryInput"
          fullWidth
          freeSolo
          options={options}
          isOptionEqualToValue={(option, value) => option.id === value.id}

          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return option;
            }
            return option.name;
          }}

          value={value}

          onChange={(event, category) => {
            setValue(category);
          }}

          onInputChange={(event, newInputValue) => {
            setValue(null)
            setInputCategoryValue(newInputValue);
          }}
          renderInput={(params) => (
            <TextField {...params} placeholder="Name of the sub-category" variant='outlined' fullWidth />
          )}
          renderOption={(props, option) => (
            <li {...props} key={uuidv4()}>
              {option.name}
            </li>
          )}
          renderTags={(tagValue, getTagProps) => {
            return tagValue.map((option, index) => (
              <Chip {...getTagProps({ index })} key={uuidv4()} label={option.name} />
            ))
          }}
        />
        <TextField
          id="description"
          label="Description"
          rows={4}
          multiline
          disabled={value?.id}
          value={value?.id ? value?.description : description}
          onChange={(event) => setDescription(event.target.value)}
          margin='dense'
          sx={{ marginTop: 2 }}
          fullWidth
          variant='outlined'
        />

      </DialogContent>
      <DialogActions sx={{ p: 4 }} >
        <Button variant='contained' disabled={!((description || value?.id) && (value || inputCategoryValue))} onClick={handleCreate}>{(value?.id) ? "Link" : "Create"}</Button>
        <Button variant='outlined' onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog >
  );
};

export default AddSubCategoryDialog;