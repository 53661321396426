
import { api } from "src/apis/configs/axios"
import { defineCancelApiObject } from "src/apis/configs/axiosUtils"

export const AuthApi = {
  getToken: async function (username, password, cancel = false) {
    const response = await api.request({
      url: `/token/`,
      method: "POST",
      data: {
        username: username,
        password: password,
      },
      // retrieving the signal value by using the property name
      signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
    }).catch((error) => {
      return { data: { error: error.response.data.detail } }
    });
    return response.data
  },
  register: async function (username, password, email, cancel = false) {
    const response = await api.request({
      url: `/api/profiles/register/`,
      method: "POST",
      data: {
        username: username,
        password: password,
        email: email,
      },
      // retrieving the signal value by using the property name
      signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
    }).catch((error) => {
      const errorData = error.response.data.message;
      return { data: { error: errorData } }
    });
    return response.data
  },

  activate: async function (token, cancel = false) {
    const response = await api.request({
      url: `/api/profiles/activate/?key=${token}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
    }).catch((error) => {
      return { data: { error: "error setting" } }
    });
    return response.data
  },

  getMyProfile: async function (cancel = false) {
    const response = await api.request({
      url: `/api/profiles/me/`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
    }).catch((error) => {
      return { data: { error: "error setting" } }
    });
    return response.data
  },

}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(AuthApi)