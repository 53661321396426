import { api } from "src/apis/configs/axios"
import { defineCancelApiObject } from "src/apis/configs/axiosUtils"

export const CategoryApi = {
  search: async function (query, cancel = false) {
    const response = await api.request({
      url: `/api/categories/?search=${query}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel ? cancelApiObject[this.search.name].handleRequestCancellation().signal : undefined,
    }).catch((error) => {
      return { data: { error: error.response.data.error } }
    });

    return response.data;
  },
  getByName: async function (name, cancel = false) {
    const response = await api.request({
      url: `/api/categories/?name=${name}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel ? cancelApiObject[this.getByName.name].handleRequestCancellation().signal : undefined,
    }).catch((error) => {
      return { data: { error: error.response.data.error } }
    });

    return response.data;
  },
  getTopLevelCategories: async function (cancel = false) {
    const response = await api.request({
      url: '/api/categories/?top_level=true',
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel ? cancelApiObject[this.getTopLevelCategories.name].handleRequestCancellation().signal : undefined,
    }).catch((error) => {
      return { data: { error: error.response.data.error } }
    });

    return response.data;
  },
  getChildren: async function (id, onlyReviewed = true, cancel = false) {
    const response = await api.request({
      url: `/api/categoryRelations/?parent=${id}&only_reviewed=${onlyReviewed}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel ? cancelApiObject[this.getChildren.name].handleRequestCancellation().signal : undefined,
    }).catch((error) => {
      return { data: { error: error.response.data.error } }
    });

    return response.data;
  },
  addChild: async function (parentId, childId, cancel = false) {
    const response = await api.request({
      url: '/api/categories/' + parentId + '/add_child/',
      method: "POST",
      data: { child_id: childId },
      // retrieving the signal value by using the property name
      signal: cancel ? cancelApiObject[this.addChild.name].handleRequestCancellation().signal : undefined,
    }).catch((error) => {
      return { data: { error: error.response.data.error } }
    });

    return response.data;
  },

  getPendingCategories: async function (cancel = false) {
    const response = await api.request({
      url: '/api/categories/get_pending_categories/',
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel ? cancelApiObject[this.getPendingCategories.name].handleRequestCancellation().signal : undefined,
    }).catch((error) => {
      return { data: { error: error.response.data.error } }
    });

    return response.data;
  },

  getPendingRelations: async function (cancel = false) {
    const response = await api.request({
      url: '/api/categories/get_pending_relations/',
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel ? cancelApiObject[this.getPendingRelations.name].handleRequestCancellation().signal : undefined,
    }).catch((error) => {
      return { data: { error: error.response.data.error } }
    });

    return response.data;
  },

  deleteRelation: async function (ids, cancel = false) {
    const response = await api.request({
      url: '/api/categories/delete_relation/',
      method: "POST",
      data: { ids },
      // retrieving the signal value by using the property name
      signal: cancel ? cancelApiObject[this.delete.name].handleRequestCancellation().signal : undefined,
    }).catch((error) => {
      return { data: { error: error.response.data.error } }
    });

    return response.data;
  },

  approveRelation: async function (ids, cancel = false) {
    const response = await api.request({
      url: '/api/categories/approve_relation/',
      method: "POST",
      data: { ids },
      // retrieving the signal value by using the property name
      signal: cancel ? cancelApiObject[this.publish.name].handleRequestCancellation().signal : undefined,
    }).catch((error) => {
      return { data: { error: error.response.data.error } }
    });

    return response.data;
  },


  createChild: async function (parentId, name, description, cancel = false) {
    const response = await api.request({
      url: '/api/categories/' + parentId + '/create_child/',
      method: "POST",
      data: { name, description },
      // retrieving the signal value by using the property name
      signal: cancel ? cancelApiObject[this.createChild.name].handleRequestCancellation().signal : undefined,
    }).catch((error) => {
      return { data: { error: error.response.data.error } }
    });

    return response.data;
  },

  delete: async function (ids, cancel = false) {
    const response = await api.request({
      url: '/api/categories/delete/',
      method: "POST",
      data: { ids },
      // retrieving the signal value by using the property name
      signal: cancel ? cancelApiObject[this.delete.name].handleRequestCancellation().signal : undefined,
    }).catch((error) => {
      return { data: { error: error.response.data.error } }
    });

    return response.data;
  },

  publish: async function (ids, cancel = false) {
    const response = await api.request({
      url: '/api/categories/publish/',
      method: "POST",
      data: { ids },
      // retrieving the signal value by using the property name
      signal: cancel ? cancelApiObject[this.publish.name].handleRequestCancellation().signal : undefined,
    }).catch((error) => {
      return { data: { error: error.response.data.error } }
    });

    return response.data;
  }

}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(CategoryApi)