import { useState } from 'react';
import Editor from 'react-simple-wysiwyg';
import { EditorProvider } from 'react-simple-wysiwyg';
import Toolbar from 'react-simple-wysiwyg';
import BtnBold from 'react-simple-wysiwyg';
import BtnItalic from 'react-simple-wysiwyg';

export default function CommentInput({ defaultValue, onChange }) {
  const [html, setHtml] = useState(defaultValue);

  function handleChange(e) {
    const value = e.target.value;
    setHtml(value);
    onChange(value);
  }

  return (
    <EditorProvider>
      <Editor containerProps={{ style: { height: "200px", resize: "vertical" } }} value={html} onChange={handleChange}>
        <Toolbar>
          <BtnBold />
          <BtnItalic />
        </Toolbar>
      </Editor>
    </EditorProvider>
  );
}