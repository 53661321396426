import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, DialogContentText } from '@mui/material';
import ListSelector from 'src/components/list-selector';

const PickListDialog = ({ open, onPick, onClose }) => {


  return (
    <Dialog open={open} maxWidth="sm" fullWidth={true} onClose={onClose}>
      <DialogTitle>Pick a list</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 3 }}>
          You can easily reference a list by picking it from the list below. It will create a link
        </DialogContentText>

        <ListSelector listOnly={false} autoFocus={true} onSelect={onPick} />

      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PickListDialog;