import { api } from "src/apis/configs/axios"
import { defineCancelApiObject } from "src/apis/configs/axiosUtils"


export const UtilsApi = {

  upload: async function (file, cancel = false) {
    const formData = new FormData();
    formData.append('file', file);

    const response = await api.request({
      url: '/api/utils/upload_file/',
      method: "POST",
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
      // retrieving the signal value by using the property name
      signal: cancel ? cancelApiObject[this.upload.name].handleRequestCancellation().signal : undefined,
    }).catch((error) => {
      return { data: { error: "error uploading" } }
    });

    return response.data;
  }



}

const cancelApiObject = defineCancelApiObject(UtilsApi)