const default_language = "en";

export const getSubdomain = () => {
  try {
    const subdomain = window.location.hostname.split('.')[0];
    return subdomain;
  } catch (error) {
    console.error("Error getting subdomain:", error);
    return null;
  }
};

export const getLanguage = () => {
  try {
    const subdomain = getSubdomain();
    const subdomain_language_map = {
      "en": "en",
      "tr": "tr",
      "localhost": "en",
    };
    return subdomain in subdomain_language_map ? subdomain_language_map[subdomain] : default_language;
  } catch (error) {
    console.error("Error getting language:", error);
    return default_language;
  }
};

export const setLanguage = (language) => {
  try {
    localStorage.setItem('i18nextLng', language);
  } catch (error) {
    console.error("Error setting language:", error);
  }
}