export const encodeTitle = (title) => {
  if (!title) {
    return '';
  }
  return title.replace(/ /g, '_');
}

export const decodeTitle = (title) => {
  if (!title) {
    return '';
  }
  return title.replace(/_/g, ' ');
}