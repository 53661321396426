import React from 'react';
import {
  Container,
  Typography,
  Box,
  Paper,
  Link,
} from '@mui/material';
import { Language as LanguageIcon } from '@mui/icons-material';
import ListSelector from 'src/components/list-selector';
import { encodeTitle } from 'src/tools/title-encoder';
import i18n from 'src/i18n';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { getSubdomain } from 'src/tools/language';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';

const languages = [
  { code: 'en', name: 'English', url: 'https://en.wikilist.co/home' },
  { code: 'tr', name: 'Türkçe', url: 'https://tr.wikilist.co/home' },
];

const Mainpage = () => {

  const subdomain = getSubdomain();
  if (subdomain) {
    // Check if the subdomain is a valid language
    const language = languages.find((language) => language.code === subdomain);

    // Redirect to the list page with the subdomain language
    if (language) {
      window.location.href = language.url;
    }
  }


  const { t } = useTranslation();

  // Get navigator preferred language
  const userLanguage = navigator.language.split('-')[0];
  const defaultLanguage = languages.find((language) => language.code === userLanguage) || languages[0];
  i18n.changeLanguage(defaultLanguage.code);


  const handleSelect = (list) => {
    // Redirect to the list page with the selected list
    const url = "https://" + list.language + ".wikilist.co/list/" + encodeTitle(list.title);
    window.location.href = url;
  }

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: "Main" });
  }, []);

  return (
    <Container maxWidth="sm">
      <Box my={4}>
        <Typography variant="h4" align="center" gutterBottom>
          <Trans>Welcome to Wikilist!</Trans>
        </Typography>
        <Typography variant="subtitle1" align="center" gutterBottom>
          <Trans>Your source for quick and concise information.</Trans>
        </Typography>
        <Paper elevation={3} sx={{ mt: 1 }}>
          <Box p={2}>
            <ListSelector allLanguages listOnly searchText={t("Search for lists")} onSelect={handleSelect} />
          </Box>
        </Paper>
        <Box mt={6}>
          {/* Display a title and subtitle for language selection */}
          <Typography variant="h6" gutterBottom>
            <Trans>Choose your language</Trans>:
          </Typography>
          {languages.map((language) => (
            <Box key={language.code} display="flex" alignItems="center" sx={{ mt: 2 }}>
              <LanguageIcon />
              <Link sx={{ ml: 1 }} href={language.url}>
                {language.name}
              </Link>
            </Box>
          ))}
        </Box>
      </Box>
    </Container>
  );
};

export default Mainpage;
