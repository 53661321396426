import {
  useBlockNoteEditor,
  useComponentsContext,
} from "@blocknote/react";
import "@blocknote/mantine/style.css";
import { useState } from "react";
import { DatasetLinkedOutlined } from "@mui/icons-material";
import PickListDialog from "../pick-list-dialog";
import { encodeTitle } from "src/tools/title-encoder";


export function LinkListButton() {
  const editor = useBlockNoteEditor();
  const [open, setOpen] = useState(false);

  const Components = useComponentsContext();

  const handleListPick = (list) => {
    setOpen(false);
    const link = `/${list.type}/` + encodeTitle(list.title);
    let urlText = list.title;

    const selection = editor.getSelectedText();
    if (selection) {
      urlText = selection;
    }
    editor.createLink(link, urlText);

  }

  return (
    <>
      <PickListDialog open={open} onPick={handleListPick} onClose={() => { setOpen(false) }} />
      <Components.FormattingToolbar.Button
        mainTooltip={"Link a list"}
        onClick={() => {
          setOpen(true);
        }}
      >
        <DatasetLinkedOutlined sx={{ width: 17 }} />
      </Components.FormattingToolbar.Button>
    </>
  );
}
