import React, { useState } from 'react';

import { Box, Button, Stack, TextField, Typography, Link, Grid, Select } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveDialog from 'src/components/save-dialog';

import ListEditor from 'src/components/list-editor';
import { ListApi } from 'src/apis/list';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { decodeTitle, encodeTitle } from 'src/tools/title-encoder';
import CategoryInput from 'src/components/category-input';
import Container from '@mui/material/Container';
import { FormControl, InputLabel, MenuItem } from '@mui/material';
import ListSelector from 'src/components/list-selector';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const codetag = (str) => {
  return (
    <code style={{
      backgroundColor: "#ddd", padding: 3, marginRight: 2,
      borderRadius: 3, fontSize: "1em", fontFamily: "monospace",
      color: "#333"

    }}>{str}</code>
  );
}

export default function ListEntry() {
  const defaultTitle = new URLSearchParams(window.location.search).get('title');
  const defaultCategories = new URLSearchParams(window.location.search).get('category_id') ? [{
    id: new URLSearchParams(window.location.search).get('category_id')
    , name: decodeTitle(new URLSearchParams(window.location.search).get('category_name'))
  }] : []



  const [title, setTitle] = useState(defaultTitle ? decodeTitle(defaultTitle) : "");
  const [content, setContent] = useState(null);
  const [changedContent, setChangedContent] = useState(null);
  const [changedTitle, setChangedTitle] = useState(null);
  const [changedCategories, setChangedCategories] = useState(null);
  const [changedTranslations, setChangedTranslations] = useState(null);
  const [categories, setCategories] = useState(defaultCategories);
  const [translations, setTranslations] = useState([]);
  const [listId, setListId] = useState(null);
  const { editingTitle } = useParams();
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [versions, setVersions] = useState([]);

  const version = new URLSearchParams(window.location.search).get('version');
  const [selectedVersion, setSelectedVersion] = useState(version);
  const [activeVersion, setActiveVersion] = useState(null);


  // If the title is passed in the URL, then we are editing an existing list
  // Load the list from the API
  useEffect(() => {
    if (editingTitle) {

      ListApi.get(editingTitle, version).then((lists) => {
        const list = lists[0];
        setListId(list.id);
        setTitle(list.title);
        setCategories(list.get_categories);
        setContent(JSON.parse(list.content));
        setActiveVersion(list.get_active_version);
        setTranslations(list.get_translations);

        if (!version) {
          setSelectedVersion(list.get_active_version.version_number);
        }
      });

      ListApi.getVersions(editingTitle).then((versions) => {
        setVersions(versions);
      });
    };
  }, [editingTitle, version]);


  const handleRemoveTranslation = (item) => {
    // Confirm
    if (window.confirm("Are you sure you want to remove this translation?")) {
      // Remove from translations
      const newTranslations = translations.filter((translation) => translation.title !== item.title);
      setTranslations(newTranslations);
      setChangedTranslations(newTranslations);
    }
  }

  const handleAddTranslation = (list) => {
    // Check if the list is already in translations
    if (translations.find((translation) => translation.title === list.title)) {
      alert("This list is already a translation of the current list.");
      return;
    }

    // Check if the language is already in translations
    if (translations.find((translation) => translation.language === list.language)) {
      alert("This language is already a translation of the current list.");
      return;
    }

    const newTranslations = [...translations, list];
    setTranslations(newTranslations);
    setChangedTranslations(newTranslations);
  }



  const onContentChange = (content) => {
    setChangedContent(content);
  };

  const handlePublish = () => {
    const newTitle = changedTitle ? changedTitle : title;
    const newContent = changedContent ? changedContent : content;
    const newCategories = changedCategories ? changedCategories : categories;

    if (newTitle.trim() === "") {
      alert("Please enter a title for your list.");
      return;
    }
    if (newContent === null) {
      alert("Please enter some content for your list.");
      return;
    }

    // Make sure content has at least one item with type="numberedListItem"
    let hasListItem = false;
    newContent.forEach((block) => {
      if (block.type === "numberedListItem" || block.type === "bulletListItem") {
        hasListItem = true;
      }
    });
    if (!hasListItem) {
      alert("Please add some numbered/bullet list items to your list. You can add them by typing '1. ' or '- ' at the beginning of a line.");
      return;
    }

    if (newCategories.length === 0) {
      alert("Please enter some categories for your list.");
      return;
    }

    setSaveDialogOpen(true);
  }

  const handleSaveReason = (reason) => {
    const newTitle = changedTitle ? changedTitle : title;
    const newContent = changedContent ? changedContent : content;
    const newCategories = changedCategories ? changedCategories : categories;
    const newTranslations = changedTranslations ? changedTranslations : translations;

    ListApi.save(listId, newTitle, newContent, reason, newCategories, newTranslations).then((response) => {
      setChangedContent(null);
      setChangedTitle(null);
      setChangedCategories(null);
      window.location.href = "/revisions/" + encodeTitle(response.title);
    });

  }

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
    setChangedTitle(event.target.value);
  }

  const handleGotoList = () => {
    window.location.href = "/list/" + encodeTitle(title);
  }

  const handleCategoriesChange = (categories) => {
    setChangedCategories(categories);
    setCategories(categories);
  }

  const handleVersionChange = (event) => {
    setSelectedVersion(event.target.value);
    window.location.href = "/edit/" + encodeTitle(editingTitle) + "?version=" + event.target.value;
  }

  return (
    <Container>
      <SaveDialog open={saveDialogOpen} onSave={handleSaveReason} onClose={() => { setSaveDialogOpen(false); }} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        {/* Text input for the title of the list */}
        {editingTitle && (
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} md={9}>

              <Typography color={"text.secondary"} variant="h4" component="h4">
                Editing: <Link href={"/list/" + editingTitle}>{title}</Link>
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              {versions.length > 0 && (
                <FormControl fullWidth>
                  <InputLabel>Version</InputLabel>
                  <Select
                    value={selectedVersion || ""}
                    label="Age"
                    onChange={handleVersionChange}
                    fullWidth
                  >
                    {versions?.map((version) => (
                      <MenuItem sx={{ fontWeight: activeVersion?.id === version.id ? "bold" : "normal" }} key={version.id} value={version.version_number}>
                        {activeVersion?.id === version.id && "Active: "}
                        {version.version_number} {version.revisionMessage ? `(${version.revisionMessage})` : ""}
                      </MenuItem>
                    ))}

                  </Select>
                </FormControl>
              )}
            </Grid>
          </Grid>
        )}

        {!editingTitle && (
          <TextField
            id="outlined-basic"
            variant="outlined"
            fullWidth
            margin="normal"
            label="Title"
            placeholder='Enter the title of the list here'
            helperText="Be descriptive and concise. This will be the title of your list."

            inputProps={{ style: { fontSize: 18, fontWeight: '500' } }} // font size of input text
            value={title}
            onChange={handleTitleChange}

          />
        )}

        <Box sx={{ minHeight: "400px", border: "solid 1px #dedede", borderRadius: 1, padding: 1, pl: 0 }} >
          <ListEditor content={content} onContentChange={onContentChange} />
        </Box>
        <Typography variant="caption" color="text.secondary" sx={{ ml: 1.5, mt: 0.5, mb: 2 }}>
          Insert your list items above. Type {codetag("1. ")} or {codetag("- ")} at the beginning of a line to create a numbered or bullet list item. Type {codetag("/cite")} to insert a citation.
        </Typography>

        <CategoryInput categories={categories} onCategoriesChange={handleCategoriesChange} />
        <Typography variant="caption" color="text.secondary" sx={{ ml: 1.5, mb: 2, mt: 0.5 }}>
          Add categories to help others find your list. You can select from existing categories or create new ones.
        </Typography>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography variant="body1" color={"text.secondary"}>
              Translations (
              {translations.length > 0 ? (
                <>This list has {translations.length} translations</>
              ) : (
                <>This list has no translations yet</>
              )})
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="h5" color="text.secondary">
              Translations:
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              We have different translations of this list in the following languages. You can add more translations by selecting a list from the dropdown below.
              You can also edit existing translations by clicking on the list title. Click [x] to remove a translation.
            </Typography>
            <Box sx={{ mb: 2, mt: 1 }}>
              {translations.length > 0 && (
                <>
                  {
                    translations?.map((translation) => (
                      <Stack direction={"row"} key={translation.title}>
                        <Typography variant="subtitle1" color="text.secondary">
                          • <span style={{ fontStyle: "italic", fontSize: "0.8rem" }}>[{translation.language}]&nbsp;</span>
                          <Link target="_blank" href={"https://" + translation.language + ".wikilist.co/list/" + encodeTitle(translation.title)}>{translation.title}</Link>
                        </Typography>
                        <Button sx={{ ml: 1, p: 0 }} variant="text" color="secondary" onClick={() => handleRemoveTranslation(translation)} size='small'>[x]</Button>
                      </Stack>
                    ))
                  }
                </>
              )}
            </Box>
            <ListSelector listOnly allLanguages searchText={"Search for a list to add to translations"} onSelect={handleAddTranslation} />
          </AccordionDetails>
        </Accordion>

      </Box>
      <Stack spacing={2} sx={{ mt: 2 }} direction="row" justifyContent={"flex-start"}>
        <LoadingButton sx={{ marginTop: 1 }} disabled={changedContent === null && changedTitle === null && changedCategories === null && changedTranslations === null} onClick={handlePublish} variant="contained" size='large' color="primary">Publish</LoadingButton>
        {listId &&
          <Button variant="text" color="primary" size='large' onClick={handleGotoList}>Go to list >></Button>
        }
      </Stack>
    </Container >

  );
}

