import { Typography, Link } from '@mui/material';
import React from 'react';
import { RiBookFill } from 'react-icons/ri';
import Box from '@mui/material/Box';


export default function ContentRenderer({ itemID, renderAslist, content }) {
  let isHeader = true;

  const trimHeader = (text) => {
    if (isHeader && !renderAslist) {
      text = text.trim();
      if (text.endsWith(":")) {
        text = text.substring(0, text.length - 1)
      }
    }
    return text;
  }

  content = (
    <>
      {
        content.map((item, index) => {
          let elements = (
            <span key={itemID + "-" + index}>
              {
                item.type === "text" &&
                <span style={{
                  fontWeight: item.styles?.bold && (!isHeader || renderAslist) ? "bold" : "normal",
                  textDecoration: item.styles?.strike ? "line-through" : "",
                  fontStyle: item.styles?.italic ? "italic" : "normal",

                }}>{trimHeader(item.text)}</span>
              }

              {
                item.type === "cite" &&
                <span style={{
                  backgroundColor: "#E77051",
                  color: "white",
                  cursor: "pointer",
                  userSelect: "none",
                  MozUserSelect: "none",
                  msUserSelect: "none",
                  paddingTop: "3px",
                  paddingLeft: "2px",
                  paddingRight: "2px",
                  borderRadius: "2px",
                }}>
                  <RiBookFill size={16} />
                </span >
              }
              {
                item.type === "link" &&
                <Link href={item.href}>
                  {item.content[0].text}
                </Link>
              }
            </span>
          )

          if (isHeader && !renderAslist) {
            elements = <Typography sx={{ marginBottom: 1 }} component="h5" variant="h5" key={itemID + "-" + index}>{elements}</Typography>
          }
          isHeader = false;

          return elements;
        })
      }
    </>
  )

  if (renderAslist) {
    return <Box sx={{ marginLeft: 2 }}>{content}</Box>
  }

  return content;

}