import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, DialogContentText } from '@mui/material';
import { ListApi } from 'src/apis/list';

const CiteDialog = ({ open, defaultUrl, onChange, onClose }) => {
  const [url, setUrl] = useState(defaultUrl);

  const handleUrlChange = (event) => {
    setUrl(event.target.value);
  };

  const handleCite = () => {
    // Check if the url is valid
    if (!url) {
      alert("URL can not be empty");
      return;
    }

    // Validate url
    // Acceptable ones: https://www.example.com, http://www.example.com, www.example.com, example.com/article
    const url_pattern = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+)(\.[a-zA-Z0-9-]+)+([/?].*)?$/;
    if (!url.match(url_pattern)) {
      alert("Invalid URL");
      return;
    }

    // Validate the url adding http:// if not present
    const http_pattern = /^https?:\/\//;
    let fetchUrl = url;
    if (!fetchUrl.match(http_pattern)) {
      fetchUrl = "http://" + fetchUrl;
    }

    ListApi.fetchUrlTitle(fetchUrl).then((response) => {
      let pageTitle = "";
      if (!response.error) {
        pageTitle = response.title;
      }
      onChange(url, pageTitle);
      onClose();
    });

  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth={true} onClose={onClose}>
      <DialogTitle>Cite Dialog</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To ensure the accuracy and credibility of the information, please add citations for any facts, statistics, or direct quotes.
        </DialogContentText>
        <TextField
          label="URL"
          value={url ? url : ""}
          onChange={handleUrlChange}
          margin='dense'
          sx={{ marginTop: 4 }}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCite} disabled={!url}>Cite</Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CiteDialog;