import React from 'react';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';


const CheckEmailView = () => {

  return (
    <Box sx={{ textAlign: 'center', mt: 10 }}>
      <Typography variant="h4" >
        Please check your email!
      </Typography>
      <Typography variant="body1" sx={{ mt: 2 }}>
        We have sent you an activation link. Please click on the link to activate and login to your account.
      </Typography>
    </Box>
  );
};

export default CheckEmailView;