import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ListApi } from 'src/apis/list';
import { useState } from 'react';
import { Container, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { TiInputChecked } from "react-icons/ti";
import { format, formatDistance } from "date-fns";
import Button from '@mui/material/Button';
import { encodeTitle } from 'src/tools/title-encoder';
import { DataGrid } from '@mui/x-data-grid';
import { decodeTitle } from 'src/tools/title-encoder';

export default function RevisionsView() {
  const { listTitle } = useParams();
  const [versions, setVersions] = useState([]);
  const [rows, setRows] = useState([]);
  const [selectedVersions, setSelectedVersions] = useState([]);

  useEffect(() => {
    if (listTitle) {

      ListApi.getVersions(listTitle).then((versions) => {
        // Sort versions by version number
        versions.sort((a, b) => {
          return parseInt(b.version_number) - parseInt(a.version_number);
        });
        setVersions(versions);
      });

    };
  }, [listTitle]);

  useEffect(() => {
    if (versions) {
      const rows = versions.map((version) => {
        return {
          id: version.version_number,
          version_number: version.version_number,
          editor: version.get_user,
          reviewed_by: version.get_reviewer,
          revisionMessage: version.revisionMessage,
          active: version.active,
          created_at: version.created_at,
          activeFrom: version.active_from,
          activeTo: (version.active_to === null && version.activeFrom != null) ? "[ACTIVE]" : version.active_to,

        }
      });
      setRows(rows);
    }
  }, [versions]);

  const getActiveVersion = () => {
    return versions.find((version) => version.active === true);
  }


  const columns = [
    {
      field: 'version_number', headerName: 'Version', width: 70,
      renderCell: (params) => {
        return (
          <>
            {params.row.active === true && <TiInputChecked />}
            {params.row.active === false &&
              <Link component={"button"} onClick={() => { handleCompareWithActive(params.row.version_number) }} sx={{ ml: 1 }}>
                v{params.row.version_number}
              </Link>
            }
            {params.row.active === true && <b>v{params.row.version_number}</b>}

          </>

        )
      }
    },
    {
      field: 'revisionMessage',
      headerName: 'Changes',
      width: 380,
      renderCell: (params) => {
        return (
          <Link href={`/list/${listTitle}/?version=${params.row.version_number}`}>

            {params.row.revisionMessage}
          </Link>
        );
      },
    },
    {
      field: 'created_at', headerName: 'Created At', width: 150,
      valueFormatter: (params) => {
        if (params === null) return ("");
        return formatDistance(new Date(params), new Date(), { addSuffix: true })
      },
    },
    { field: 'editor', headerName: 'Created by', width: 120 },
    { field: 'reviewed_by', headerName: 'Reviewed by', width: 120 },
    {
      field: 'activeFrom', headerName: 'Active From', width: 105,
      valueFormatter: (params) => {
        if (params === null) return ("");
        return format(new Date(params), "yyyy-MM-dd");
      },
    },
    {
      field: 'activeTo', headerName: 'Active To', width: 105,
      valueFormatter: (params) => {
        if (params === null) return ("");
        if (params === "[ACTIVE]") return (params);
        return format(new Date(params), "yyyy-MM-dd HH:mm");
      },

    },

  ];

  const handleCompareWithActive = (targetVersion = null) => {
    let versionLeft = targetVersion;
    if (targetVersion === null) {
      if (selectedVersions.length > 0) {
        versionLeft = selectedVersions[0];
      } else {
        return;
      }
    }
    let versionRight = getActiveVersion().version_number;

    if (versionLeft === versionRight) {
      return;
    }

    // Make the lower version left
    if (parseInt(versionLeft) > parseInt(versionRight)) {
      const temp = versionLeft;
      versionLeft = versionRight;
      versionRight = temp;
    }

    window.location.href = `/compare/${encodeTitle(listTitle)}/${versionLeft}/${versionRight}`;
  }

  const handleCompareSelected = () => {
    window.location.href = `/compare/${encodeTitle(listTitle)}/${selectedVersions[0]}/${selectedVersions[1]}`;
  }

  return (
    <Container>
      <Stack id="features">
        <Typography color={"text.secondary"} variant='h4' component={"h4"}>
          Revisions:&nbsp;
          <Link href={`/list/${listTitle}`} sx={{ mb: 2 }}>
            {decodeTitle(listTitle)}
          </Link>
        </Typography>
        {/* Explanation */}
        <Typography variant={"subtitle1"} sx={{ mt: 1.5 }}>
          This page shows all the revisions of the list: <i>{decodeTitle(listTitle)}</i>. Bold revision is the active version of this list. You can compare revisions by selecting two of them. Click on the version number to view it.
          You can also click the changes message to compare the selected version with the active version.
        </Typography>
        <Stack direction={"row"} spacing={2} sx={{ mb: 2, mt: 3 }}>
          <Button variant="contained" size="small" disabled={selectedVersions.length !== 1} color="primary" onClick={handleCompareWithActive}>Compare with active version</Button>
          <Button variant="contained" size="small" disabled={selectedVersions.length !== 2} color="primary" onClick={handleCompareSelected}>Compare selected</Button>
        </Stack>
        <DataGrid
          rows={rows}
          columns={columns}
          checkboxSelection
          onRowSelectionModelChange={(params) => {
            setSelectedVersions(params);
          }}
        />
        <Stack direction={"row"} spacing={2} sx={{ mb: 2, mt: 2 }}>
          <Button variant="contained" size="small" disabled={selectedVersions.length !== 1} color="primary" onClick={handleCompareWithActive}>Compare with active version</Button>
          <Button variant="contained" size="small" disabled={selectedVersions.length !== 2} color="primary" onClick={handleCompareSelected}>Compare selected</Button>
        </Stack>
      </Stack >
    </Container>
  );


}