import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ListApi } from 'src/apis/list';
import { useState } from 'react';
import { Grid, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import ReactDiffViewer from 'react-diff-viewer';
import { IoArrowBack } from "react-icons/io5";
import { decodeTitle } from 'src/tools/title-encoder';

export default function CompareView() {
  const { listTitle, versionLeft, versionRight } = useParams();

  const [listLeft, setListLeft] = useState(null);
  const [listRight, setListRight] = useState(null);


  useEffect(() => {
    if (listTitle) {

      ListApi.getMarkdown(listTitle, versionLeft).then((list) => {
        setListLeft(list);
      });

      ListApi.getMarkdown(listTitle, versionRight).then((list) => {
        setListRight(list);
      });

    };
  }, [listTitle, versionLeft, versionRight]);

  const newStyles = {
    variables: {
      dark: {
        highlightBackground: '#fefed5',
        highlightGutterBackground: '#ffcd3c',
      },
    },
    line: {
      padding: '0',
      margin: '0',

      '&:hover': {
        background: '#efefef',
      },
    },
  };


  return (
    <>
      <Stack id="features" sx={{ p: 0, m: 0, minWidth: "100%" }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          {/* Link back to revisions page */}
          <Link href={`/revisions/${listTitle}`} variant="subtitle1" sx={{ width: "200px" }}>
            <IoArrowBack /> Back to Revisions
          </Link>
          <Typography color="text.secondary" variant="h4" sx={{ mt: 2, mb: 1 }}>
            Compare: <Link href={`/list/${listTitle}`}>{decodeTitle(listTitle)}</Link>
          </Typography>

          {/* Describe this page in detail */}
          <Typography variant="body1" sx={{ mt: 0, mb: 3 }}>
            This page allows you to compare two versions of the same list. The differences between the two versions are highlighted in red (left) and green (right).
            You can click on the list titles to view that specific version of the list.
          </Typography>

          {listLeft && listRight &&
            <Stack spacing={2}>
              <Grid container>
                <Grid item xs>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    <Link href={`/list/${listTitle}/?version=${listLeft.version}`}>{listLeft.title} v{versionLeft}</Link>
                  </Typography>
                  <Typography variant="subtitle1" sx={{ mt: 1, mb: 1, lineHeight: "5px" }}>Changes: {listLeft.revisionMessage}</Typography>
                </Grid>
                <Grid item xs sx={{ textAlign: "right" }}>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    <Link href={`/list/${listTitle}/?version=${listRight.version}`}>
                      {listRight.title} v{versionRight}
                    </Link>
                  </Typography>
                  <Typography variant="subtitle1" sx={{ mt: 1, mb: 1, lineHeight: "5px" }}>Changes: {listRight.revisionMessage}</Typography>
                </Grid>
              </Grid>
              <Typography variant="caption" sx={{ mt: 2, mb: 2, lineHeight: "5px" }}>
                <ReactDiffViewer styles={newStyles} oldValue={listLeft.markdown} newValue={listRight.markdown} splitView={true} />
              </Typography>
            </Stack>
          }
        </Box>
      </Stack >
    </>
  );


}