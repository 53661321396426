import { createReactInlineContentSpec } from "@blocknote/react";
import { GoCrossReference } from "react-icons/go";
import CiteDialog from "src/components/cite-dialog";
import React from "react";
import { useBlockNoteEditor } from "@blocknote/react";
import { HtmlTooltip } from "src/components/html-tooltip";
import Typography from "@mui/material/Typography";
import { v4 as uuidv4 } from "uuid";


export const Cite = createReactInlineContentSpec(
  {
    type: "cite",
    propSchema: {
      id: {
        default: "",
      },
      url: {
        default: null,
      },
      title: {
        default: null,
      },
    },
    content: "none",
  },
  {
    render: (props) => (
      <InlineCiteItem props={props} />
    ),
  }
);

const InlineCiteItem = ({ props }) => {

  const [open, setOpen] = React.useState(false);
  const [url, setUrl] = React.useState(props.inlineContent.props.url);
  const [title, setTitle] = React.useState(props.inlineContent.props.title);
  const editor = useBlockNoteEditor();

  // Make open true when the item is added to the editor.
  React.useEffect(() => {
    setOpen(url === null);
  }, [url]);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  }

  const traverseItem = (item, updatedUrl, updatedTitle) => {
    return item.content.map((content) => {
      if (content.props?.id === props.inlineContent.props.id) {
        // Update the url of the cite item
        const newItem = {
          ...content,
          props: {
            ...content.props,
            url: updatedUrl,
            title: updatedTitle
          }
        };

        return newItem;
      } else {
        return content;
      }
    });

  }

  const traverseChildren = (block, updatedUrl, updatedTitle) => {
    return block.children.map((child) => {
      return {
        ...child,
        content: child.content.map((content) => {
          if (content.props?.id === props.inlineContent.props.id) {
            // Update the url of the cite item
            const newItem = {
              ...content,
              props: {
                ...content.props,
                url: updatedUrl,
                title: updatedTitle
              }
            };

            return newItem;
          } else {
            return content;
          }
        })
      };
    });
  }

  // Recursive function to find the block that contains the cite item
  // Cite item can be a part of block.content or block.children
  const findCiteinBlockContentOrChildren = (blocks) => {
    for (let block of blocks) {
      if (block.content) {
        for (let content of block.content) {
          if (content.props?.id === props.inlineContent.props.id) {
            return block;
          }
        }
      }
      if (block.children) {
        const found = findCiteinBlockContentOrChildren(block.children);
        if (found) {
          return found;
        }
      }
    }
    return null;


  }

  const handleUrl = (updatedUrl, updatedTitle) => {
    setOpen(false);
    setUrl(updatedUrl);
    setTitle(updatedTitle);

    // Find the block that contains the cite item
    const blocks = editor.document;
    const block = findCiteinBlockContentOrChildren(blocks);

    if (!block) {
      console.error("Block not found with id: ", props.inlineContent.id)
      return;
    }

    const updatedBlock = {
      id: block.id,
      type: block.type,
      props: block.props,
      content: traverseItem(block, updatedUrl, updatedTitle),
      children: block.children ? traverseChildren(block, updatedUrl, updatedTitle) : null
    };

    editor.replaceBlocks([block.id], [updatedBlock]);

  }

  return (
    <>
      <HtmlTooltip
        title={
          <React.Fragment>
            <Typography variant='subtitle2' color="inherit">{title}</Typography>
            <Typography variant='caption' color="inherit">Available at: {url}</Typography>
          </React.Fragment>
        }
      >
        <span style={{
          backgroundColor: url ? "#E77051" : "red",
          color: "white",
          cursor: "pointer",
          userSelect: "none",
          MozUserSelect: "none",
          msUserSelect: "none",
          paddingTop: "2px",
          paddingLeft: "2px",
          paddingRight: "2px",
          borderRadius: "2px",
        }} onClick={handleClick}>
          <GoCrossReference size={14} />
        </span>
      </HtmlTooltip>
      <CiteDialog open={open} defaultUrl={url} onChange={handleUrl} onClose={handleClose} />
    </>
  );

}

export const insertCite = (editor) => ({
  title: "Add cite",
  onItemClick: () => {
    editor.insertInlineContent([
      {
        type: "cite",
        props: {
          url: null,
          title: null,
          id: uuidv4()
        }
      }
    ]);

  },
  aliases: ["ref", "cite"],
  group: "Other",
  icon: <GoCrossReference size={18} />,
  subtext: "Cite a source",
});