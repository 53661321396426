import "@blocknote/core/fonts/inter.css";
import { CreateLinkButton, FormattingToolbar, useCreateBlockNote } from "@blocknote/react";
import { BlockNoteView } from "@blocknote/mantine";
import "@blocknote/mantine/style.css";
import { BasicTextStyleButton } from "@blocknote/react";
import { FormattingToolbarController } from "@blocknote/react";
import { NestBlockButton } from "@blocknote/react";
import { UnnestBlockButton } from "@blocknote/react";
import { BlockNoteSchema } from "@blocknote/core";
import { defaultInlineContentSpecs } from "@blocknote/core";
import { SuggestionMenuController } from "@blocknote/react";
import { filterSuggestionItems } from "@blocknote/core";
import { Cite } from "./cite";
import { getDefaultReactSlashMenuItems } from "@blocknote/react";
import { insertCite } from "./cite";
import { LinkListButton } from "../link-list-button";
import { UtilsApi } from "src/apis/utils";

const defaultContent = [{
  type: "numberedListItem",
  content: [{
    type: "text",
    text: "Your first list item",
    styles: { bold: "bold" },
  }],
  children: [{
    type: "bulletListItem",
    content: [{
      type: "text",
      text: "Sub-item: ",
      styles: { bold: "bold" },
    },
    {
      type: "text",
      text: "Description",
      styles: {}
    }
    ]
  }]
}];

const filteredSlashMenuItems = (editor) => {
  const valid = [
    "numbered_list",
    "bullet_list",
    "check_list",
    "blockquote",
    "image"
  ]
  const defaultSlashMenuItems = getDefaultReactSlashMenuItems(editor);
  return defaultSlashMenuItems.filter((item) => valid.includes(item.key));
}

const getCustomSlashMenuItems = (editor) => [
  ...filteredSlashMenuItems(editor),
  insertCite(editor),
];

const schema = BlockNoteSchema.create({
  inlineContentSpecs: {
    ...defaultInlineContentSpecs,
    cite: Cite,
  }
});

const ListEditor = ({ content, onContentChange }) => {

  // Uploads a file to tmpfiles.org and returns the URL to the uploaded file.
  async function uploadFile(file) {
    const response = await UtilsApi.upload(file);
    return response.url;
  }

  // Creates a new editor instance.
  const editor = useCreateBlockNote({
    schema: schema,
    initialContent: content || defaultContent,
    uploadFile: uploadFile,
  }, [content]);

  const onChange = async (value) => {
    onContentChange(editor.document)
  }

  // Renders the editor instance using a React component.
  return <BlockNoteView editor={editor}
    onChange={onChange}
    formattingToolbar={false}
    slashMenu={false}
    sx={{
      height: "500px"
    }}
  >
    <SuggestionMenuController
      triggerCharacter={"/"}
      // Replaces the default Slash Menu items with our custom ones.
      getItems={async (query) =>
        filterSuggestionItems(getCustomSlashMenuItems(editor), query)
      }
    />
    <FormattingToolbarController
      formattingToolbar={() => (
        <FormattingToolbar>
          {/* Add custom formatting buttons here. */}
          <BasicTextStyleButton
            basicTextStyle={"bold"}
            key={"boldStyleButton"}
          />
          <BasicTextStyleButton
            basicTextStyle={"italic"}
            key={"italicStyleButton"}
          />
          <BasicTextStyleButton
            basicTextStyle={"strike"}
            key={"strikeStyleButton"}
          />
          <LinkListButton key={"linkListButton"} />
          <CreateLinkButton key={"createLinkButton"} />
          <NestBlockButton key={"nestBlockButton"} />
          <UnnestBlockButton key={"unnestBlockButton"} />
        </FormattingToolbar>
      )} />
  </BlockNoteView>;
}

export default ListEditor;