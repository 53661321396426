import React, { useEffect, useState } from 'react';
import { ListApi } from 'src/apis/list';
import Box from '@mui/material/Box';
import HomePageListRenderer from 'src/components/homepage-list-renderer';
import WelcomeCard from 'src/components/welcome-card';
import { Link, Stack, Typography } from '@mui/material';
import { encodeTitle } from 'src/tools/title-encoder';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';

export default function EditableHomePage() {
  const [list, setList] = useState(null);
  const [document, setDocument] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    ListApi.getHomepage().then((list) => {
      setList(list);
      let doc = JSON.parse(list.content);
      setDocument(doc);
    });
  }, []);


  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: "Home" });
  }, []);

  return (
    <>
      <Box sx={{ bgcolor: 'background.default' }}>
        <Box sx={{ px: 2, mt: 1 }}>
          <WelcomeCard />
        </Box>
        <Stack sx={{ marginTop: 2, px: 2 }} direction="row" spacing={1} justifyContent="flex-end">
          <Typography variant="subtitle1">
            <Trans>Contribute to Wikilist Homepage</Trans>:
          </Typography>
          <Typography variant="subtitle1">
            <Link href={`/edit/${encodeTitle(list?.title)}`}>{t("list.Edit")}</Link>
          </Typography>
          <Box>•</Box>
          <Typography variant="subtitle1">
            <Link href={`/discuss/${encodeTitle(list?.title)}`}>{t("list.Discuss")}</Link>
          </Typography>
          <Box>•</Box>
          <Typography variant="subtitle1">
            <Link href={`/revisions/${encodeTitle(list?.title)}`}>{t("list.Revisions")}</Link>
          </Typography>
        </Stack>
        <Box sx={{ px: 2 }}>
          <HomePageListRenderer isTopLevel={true} document={document} />
        </Box>
      </Box >
    </>
  );
}