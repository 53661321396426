import React from 'react';
import Link from '@mui/material/Link';
import { GoCrossReference } from "react-icons/go";
import Typography from '@mui/material/Typography';
import { HtmlTooltip } from 'src/components/html-tooltip';


export default function ContentRenderer({ itemID, content }) {

  return (
    <>
      {
        content.map((item, index) => {
          return (
            <span key={itemID + "-" + index}>
              {
                item.type === "text" &&
                <span style={{
                  fontWeight: item.styles?.bold ? "bold" : "normal",
                }}>{item.text}</span>
              }

              {
                item.type === "cite" &&
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography variant='subtitle2' color="inherit">{item.props?.title}</Typography>
                      <Typography variant='caption' color="inherit">Available at: {item.props?.url}</Typography>
                    </React.Fragment>
                  }
                >
                  <Link target="_blank" href={item.props.url}>
                    <span style={{
                      backgroundColor: "#E77051",
                      color: "white",
                      cursor: "pointer",
                      userSelect: "none",
                      MozUserSelect: "none",
                      msUserSelect: "none",
                      paddingTop: "2px",
                      paddingLeft: "2px",
                      paddingRight: "3px",
                      borderRadius: "2px",
                    }}>
                      <GoCrossReference size={14} />
                    </span >
                  </Link>
                </HtmlTooltip>
              }
              {
                item.type === "link" &&
                <a href={item.href}>
                  <ContentRenderer itemID={index} content={item.content} />
                </a >
              }
            </span>
          )
        })
      }
    </>
  )

}