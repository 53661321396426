import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField } from '@mui/material';

const SaveDialog = ({ open, onClose, onSave }) => {
  const [reason, setReason] = useState('');

  const handleSave = () => {
    onSave(reason);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth={true}>
      <DialogTitle>Update List</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please tell about the changes you made to this list.
        </DialogContentText>

        <TextField
          autoFocus
          margin="dense"
          label="Reason"
          fullWidth
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} disabled={!reason}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SaveDialog;