import * as React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';

const VisionButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 12px',
  border: '1px solid',
  color: 'white',
  lineHeight: 1.5,
  backgroundColor: 'transparent',
  borderColor: '#fff',
  '&:hover': {
    backgroundColor: '#fff',
    borderColor: '#fff',
    boxShadow: 'none',
    color: 'black',
  },
});

function WelcomeCard() {
  const { t } = useTranslation();
  return (
    <Paper
      sx={{
        position: 'relative',
        backgroundColor: 'grey.800',
        color: '#fff',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url(/earth-welcome.jpg)`,
      }}
    >
      {/* Increase the priority of the hero background image */}
      {<img style={{ display: 'none' }} image={"/earth-welcome.jpg"} alt="Welcome to Wikilist" />}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          backgroundColor: 'rgba(0,0,0,.1)',
        }}
      />
      <Grid container>
        <Grid item md={9}>
          <Box
            sx={{
              position: 'relative',
              p: { xs: 3, md: 5 },
              pr: { md: 0 },
            }}
          >
            <Typography variant="h4" component={"h1"} color="inherit" paragraph>
              <Trans>Welcome to Wikilist!</Trans>
            </Typography>
            <Typography variant="h5" component={"h2"} color="inherit" paragraph>
              <Trans>Our Mission</Trans>
            </Typography>
            {/* White button */}
            <VisionButton variant="outlined" color="primary" href={t("bar.our_vision_link")}>
              <Trans>Read Our Vision</Trans>
            </VisionButton>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default WelcomeCard;