import NumberedListItem from './numbered-list-item';
import BulletListItem from './bullet-list-item';
import { CardContent, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';

export default function HomePageListRenderer({ document, isTopLevel = false }) {
  const renderListItems = (items, startIndex) => {
    const listItems = [];
    let index = startIndex;

    while (index < items.length && items[index].type === 'numberedListItem') {
      listItems.push(
        <NumberedListItem isTopLevel={isTopLevel} key={index} item={items[index]} />
      );
      index++;
    }

    return { listItems, nextIndex: index };
  };

  const renderBulletListItems = (items, startIndex) => {
    const listItems = [];
    let index = startIndex;

    while (index < items.length && items[index].type === 'bulletListItem') {
      listItems.push(
        <Grid item xs key={index}>
          <Card sx={{ minWidth: 275 }} variant='outlined'>
            <CardContent sx={{ paddingBottom: "16px !important" }}>
              <BulletListItem key={index} item={items[index]} />
            </CardContent>
          </Card>
        </Grid>
      );
      index++;
    }

    return { listItems, nextIndex: index };
  }

  const renderDocument = (items) => {
    let renderedElements = [];
    let index = 0;

    if (!items) {
      return null;
    }

    while (index < items.length) {
      const item = items[index];

      if (item.type === 'numberedListItem') {
        const { listItems, nextIndex } = renderListItems(items, index);
        renderedElements.push(
          listItems
        );
        index = nextIndex;
      } else if (item.type === 'bulletListItem') {
        const { listItems, nextIndex } = renderBulletListItems(items, index);
        renderedElements.push(
          <Grid container spacing={3} key={item.id} >
            {listItems}
          </Grid>
        );
        index = nextIndex;
      } else {
        index++;
      }
    }

    if (isTopLevel) {
      //Add stack to the top
      return (
        <Stack spacing={3}>
          {renderedElements}
        </Stack>
      );

    }

    return renderedElements;
  };

  return renderDocument(document);




}