import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Container, Typography } from '@mui/material';
import { AuthApi } from 'src/apis/auth';
import { Link } from 'react-router-dom';

export default function ActivateView() {
  const [activating, setActivating] = useState(true);

  useEffect(() => {
    const token = window.location.pathname.split('/')[2]
    AuthApi.activate(token)
      .then(() => {
        setActivating(false);
      })
      .catch(() => {
        setActivating(false);
      });
  });

  return (
    <>

      <Container
        sx={{
          pt: { xs: 4, sm: 12 },
          pb: { xs: 8, sm: 16 },
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", md: '60%' },
            textAlign: { xs: 'left', md: 'center' },
          }}
        >
          {activating && (
            <>
              <CircularProgress />
              <Typography variant="h4" gutterBottom>
                Activating your account
              </Typography>
            </>
          )}
          {!activating && (
            <>
              <Typography variant="h5" gutterBottom>
                Account activated. You can now login.
              </Typography>

              <Link to="/login">
                <Typography variant="h6">
                  Login
                </Typography>
              </Link>
            </>
          )}
        </Box>
      </Container>

    </>
  );
}
