import React from 'react';
import { Helmet } from 'react-helmet-async';

export default function HelmetTag({ metaTitle, metaKeywords, metaDescription }) {
  return (
    <Helmet>
      <title>{metaTitle}</title>
      <meta name="keywords" content={metaKeywords} />
      <meta name="description" content={metaDescription} />

      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:type" content="article" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:site" content="@wikilist_org" />

    </Helmet>
  );
}
