// Go through the content (block -> content, block -> children -> content) to find the count of cites
export const countCitesAndItems = (content) => {
  let cites = 0;
  let listItems = 0;
  content.forEach((block) => {
    if (block.type === "cite") {
      cites++;
    }

    if (["numberedListItem", "bulletListItem"].indexOf(block.type) > -1) {
      listItems++;
    }

    if (block.content) {
      let result = countCitesAndItems(block.content);
      cites += result.cites;
      listItems += result.listItems;
    }

    if (block.children) {
      let result = countCitesAndItems(block.children);
      cites += result.cites;
      listItems += result.listItems;
    }
  });
  return { cites, listItems };
}

export const trim = (str, maxLength) => {
  if (str.length > maxLength) {
    return str.slice(0, maxLength) + '...';
  }
  return str;
}