
import LoginCoverView from './auth/login';
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Routes, Route } from "react-router-dom";
import ListEntry from './list/entry';
import ListView from './list/view';
import EditableHomePage from './home/editable-home-page';
import React from 'react';
import AppAppBar from 'src/components/app-bar';
import { Container } from '@mui/material';
import Footer from './components/footer';
import CategoryIndexView from './category';
import SubCategoryIndexView from './category/sub-category';
import Discuss from './discuss';
import RegisterView from './auth/register';
import ActivateView from './auth/activate';
import CheckEmailView from './auth/check-email';
import RevisionsView from './list/revisions';
import CompareView from './list/compare';
import Mainpage from './home/main-page';
import { HelmetProvider } from 'react-helmet-async';
import ReactGA from 'react-ga4';
import { SnackbarProvider } from 'notistack';
import CategoryAdminView from './admin/categories';
import CategoryRelationsAdminView from './admin/categoryrelations';


function App() {
  // default theme
  const [mode, setMode] = React.useState(localStorage.getItem('mode') || 'light');
  const defaultTheme = createTheme({
    palette: {
      mode: mode,

      primary: {
        main: '#E77051',
      },
      secondary: {
        main: '#888',
      },
      success: {
        main: '#2391AA',
      },
    },
    typography: {
      allVariants: {
        fontFamily: '"Open Sans", sans-serif',
      },
    },

  },);

  const toggleColorMode = () => {
    // Update state and local storage
    const newMode = mode === 'light' ? 'dark' : 'light';
    setMode(newMode);
    localStorage.setItem('mode', newMode);

  }

  ReactGA.initialize('G-FJ44X1ETN0',
    {
      gaOptions: {
        cookieFlags: 'SameSite=None; Secure'
      }
    }

  );




  return (
    <HelmetProvider>
      <ThemeProvider theme={defaultTheme}>
        <SnackbarProvider maxSnack={3}>
          <CssBaseline />
          {/* Hide top bar for mainPage */}
          {window.location.pathname !== '/' && (
            <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
          )}
          <Container
            sx={{
              pt: { xs: 1, sm: 3 },
              pb: { xs: 2, sm: 8 },
              pl: 0,
              pr: 0,
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >

            <Routes>
              <Route path="/check_email" element={<CheckEmailView />} />
              <Route path="/register" element={<RegisterView />} />
              <Route path="/activate/:activationKey" element={<ActivateView />} />
              <Route path="/login" element={<LoginCoverView />} />
              <Route path='/' element={<Mainpage />} />
              <Route path='/home' element={<EditableHomePage />} />
              <Route path='/create' element={<ListEntry />} />
              <Route path='/edit/:editingTitle' element={<ListEntry />} />
              <Route path='/list/:listTitle' element={<ListView />} />
              <Route path='/categories' element={<CategoryIndexView />} />
              <Route path='/category/:parent' element={<SubCategoryIndexView />} />
              <Route path='/discuss/:listTitle' element={<Discuss />} />
              <Route path='/revisions/:listTitle' element={<RevisionsView />} />
              <Route path='/compare/:listTitle/:versionLeft/:versionRight' element={<CompareView />} />
              <Route path='/discuss/:listTitle/:commentId' element={<Discuss />} />

              {/* Admin */}
              <Route path='/manage/categories' element={<CategoryAdminView />} />
              <Route path='/manage/category_relations' element={<CategoryRelationsAdminView />} />
            </Routes>
          </Container>

          <Footer />
        </SnackbarProvider>
      </ThemeProvider >
    </HelmetProvider>
  );
}

export default App;
