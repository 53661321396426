import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { getLanguage } from "src/tools/language"
import transalationEn from "src/locales/en.json";
import transalationTr from "src/locales/tr.json";

const resources = {
  en: {
    translation: transalationEn
  },
  tr: {
    translation: transalationTr
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    interpolation: {
      escapeValue: false // react already safes from xss
    },
  });

i18n.changeLanguage(getLanguage());

export default i18n;