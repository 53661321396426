export const getUser = () => {
  return JSON.parse(localStorage.getItem('user'));
}

export const isNormalUser = () => {
  const user = getUser();
  if (user) {
    return user.role === 'U';
  }
  return false;
}

export const isAdmin = () => {
  const user = getUser();
  if (user) {
    return user.role === 'A';
  }
  return false;
}

export const isListerian = () => {
  const user = getUser();
  if (user) {
    return user.role === 'L';
  }
  return false;
}

export const isAuthenticated = () => {
  return getUser() !== null;
}