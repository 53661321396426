import { Box } from '@mui/material';
import HomePageListRenderer from '../homepage-list-renderer';
import ContentRenderer from './content-renderer';

export default function NumberedListItem({ item, isTopLevel = false }) {
  return (
    <Box
    >
      <ContentRenderer renderAslist={!isTopLevel} itemID={item.id} content={item.content} />
      <HomePageListRenderer itemID={item.id} document={item.children} />
    </Box>
  );
}
