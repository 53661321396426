import { Box } from '@mui/material';
import ListRenderer from 'src/components/list-renderer';
import ContentRenderer from './content-renderer';

export default function NumberedListItem({ item }) {
  return (
    <Box
    >
      <ContentRenderer itemID={item.id} content={item.content} />
      <ListRenderer topLevel={false} itemID={item.id} document={item.children} />
    </Box>
  );
}
