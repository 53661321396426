import React, { useEffect } from 'react';
import { useState } from 'react';
import { Container, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { TiDelete } from "react-icons/ti";
import { formatDistance } from "date-fns";
import Button from '@mui/material/Button';
import { encodeTitle } from 'src/tools/title-encoder';
import { DataGrid } from '@mui/x-data-grid';
import { CategoryApi } from 'src/apis/category';
import { TiTickOutline } from "react-icons/ti";


export default function CategoryRelationsAdminView() {
  const [relations, setRelations] = useState([]);
  const [selectedRelations, setSelectedRelations] = useState([]);

  useEffect(() => {
    CategoryApi.getPendingRelations().then((relations) => {
      setRelations(relations);
    });
  }, []);

  const handleApprove = (params) => {
    if (params === undefined) {
      params = selectedRelations;
    }

    CategoryApi.approveRelation(params).then((response) => {
      if (response.error) {
        alert("Error approving relation: " + response.error);
      } else {
        // Remove the published categories from the list
        setRelations(relations.filter((relation) => !params.includes(relation.id)));
      }

    });

  }

  const handleDelete = (params) => {
    // Confirm
    if (window.confirm("Are you sure you want to delete the selected relations?")) {
      if (params === undefined) {
        params = selectedRelations;
      }
      CategoryApi.deleteRelation(params).then((response) => {
        if (response.error) {
          alert("Error deleting relations: " + response.error);
        } else {
          // Remove the deleted categories from the list
          setRelations(relations.filter((relation) => !params.includes(relation.id)));
        }

      });
    }
  }

  const columns = [
    {
      field: 'name', headerName: 'Category', width: 300, renderCell: (params) => {
        return <Link href={`/category/${encodeTitle(params.row.name)}`} target="_blank">
          {params.row.name}
        </Link>
      }
    },
    {
      field: 'type', headerName: 'Parent', width: 300, renderCell: (params) => {
        return <Link href={`/category/${encodeTitle(params.row.parent_name)}`} target="_blank">
          {params.row.parent_name}
        </Link>
      }
    },
    {
      field: 'created_at', headerName: 'Created', width: 150, valueFormatter: (params) => {
        if (params === null) return ("");
        return formatDistance(new Date(params), new Date(), { addSuffix: true })
      },

    },
    {
      field: 'author', headerName: 'User', width: 140
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <Stack direction={"row"} sx={{ p: 1 }} height={"100%"} spacing={0.5}>
          <Button variant="outlined" size="small" color="primary" onClick={() => handleApprove([params.row.id])}>
            <TiTickOutline />
          </Button>
          <Button variant="outlined" size="small" color="primary" onClick={() => handleDelete([params.row.id])}>
            <TiDelete />
          </Button>
        </Stack>
      )
    }

  ]

  return (
    <Container>
      <Stack>
        <Typography color={"text.secondary"} variant='h4' component={"h4"}>
          Pending Category Relations
        </Typography>
        {/* Explanation */}
        <Typography variant={"subtitle1"} sx={{ mt: 1.5 }}>
          Here you can approve or delete category relations. Click on the category name to view it under the category page. The parent column shows the parent category of the relation.
        </Typography>
        <Stack direction={"row"} spacing={2} sx={{ mb: 2, mt: 3 }}>
          <Button variant="contained" disabled={selectedRelations.length === 0} color="primary" onClick={() => handleApprove()}><TiTickOutline /> Approve</Button>
          <Button variant="contained" disabled={selectedRelations.length === 0} color="primary" onClick={() => handleDelete()}><TiDelete /> Delete</Button>
        </Stack>
        <DataGrid
          rows={relations}
          columns={columns}
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={(params) => {
            setSelectedRelations(params);
          }}
        />
      </Stack >
    </Container>
  );

}