import { Container, Divider, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CategoryApi } from 'src/apis/category';
import Stack from '@mui/material/Stack';
import { encodeTitle } from 'src/tools/title-encoder';
import Link from '@mui/material/Link';
import { ListApi } from 'src/apis/list';
import InfoIcon from '@mui/icons-material/Info';
import Alert from '@mui/material/Alert';
import HelmetTag from 'src/components/helmet-tag';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import AddSubCategoryDialog from 'src/components/add-sub-category-dialog';
import { useSnackbar } from 'notistack';
import CategoryAddedDialog from 'src/components/category-added-dialog';
import { trim } from 'src/tools/list';

export default function SubCategoryIndexView() {
  const { t } = useTranslation();
  const { parent } = useParams();
  const [categories, setCategories] = React.useState([]);
  const [lists, setLists] = React.useState([]);
  const [category, setCategory] = React.useState(null);
  const [openSubCategoryDialog, setOpenSubCategoryDialog] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [addedCategory, setAddedCategory] = React.useState(null);

  useEffect(() => {
    CategoryApi.getByName(parent).then((categories) => {
      if (categories.length > 0) {
        setCategory(categories[0]);
      }
    });


    ListApi.getListsByCategory(parent, false).then((lists) => {
      setLists(lists);
    });

    CategoryApi.getChildren(parent, true).then((categories) => {
      setCategories(categories);
    });
  }, [parent])

  if (!category) {
    return (
      <>  </>
    );
  }

  const groupCategories = (categories) => {
    let cats = categories.reduce((acc, category) => {
      const firstLetter = category.get_child.name.charAt(0).toUpperCase();
      if (!acc[firstLetter]) {
        acc[firstLetter] = [];
      }
      acc[firstLetter].push(category);
      return acc;
    }, {});

    cats = Object.keys(cats).sort().reduce(
      (obj, key) => {
        obj[key] = cats[key];
        return obj;
      },
      {}
    );

    return cats

  };

  let groupedCategories = groupCategories(categories);


  const groupLists = (lists) => {
    let items = lists.reduce((acc, list) => {
      const firstLetter = list.title.charAt(0).toUpperCase();
      if (!acc[firstLetter]) {
        acc[firstLetter] = [];
      }
      acc[firstLetter].push(list);
      return acc;
    }, {});

    items = Object.keys(items).sort().reduce(
      (obj, key) => {
        obj[key] = items[key];
        return obj;
      },
      {}
    );
    return items;
  }

  const groupedLists = groupLists(lists);


  const title = category.name + ' - ' + t("Wikilist");
  const keywords = categories.map((category) => category.get_child.name).join(", ") + ", " + lists.map((list) => list.title).join(", ");
  const description = category.description;

  const handleCreateCategoryClick = () => {
    setOpenSubCategoryDialog(true);
  }

  const handleCategoryCreate = (subCategory) => {
    setOpenSubCategoryDialog(false);
    if (subCategory.id) {
      // Adding a child category to the parent category
      CategoryApi.addChild(category.id, subCategory.id).then((response) => {
        if (response.error) {
          enqueueSnackbar(response.error, { variant: 'error' });
          return;
        } else {
          setAddedCategory(response);
        }
      });
    } else {
      // Creating a new category under the parent category
      CategoryApi.createChild(category.id, subCategory.name, subCategory.description).then((response) => {
        if (response.error) {
          enqueueSnackbar(response.error, { variant: 'error' });
          return;
        } else {
          setAddedCategory(response);
        }
      });

    }

  }

  // List categories like an index categorized with their first letter
  return (
    <Container>
      <HelmetTag
        metaTitle={title}
        metaKeywords={keywords}
        metaDescription={description}
      />
      <CategoryAddedDialog open={addedCategory !== null} addedCategory={addedCategory} onClose={() => { setAddedCategory(null) }} />
      <AddSubCategoryDialog open={openSubCategoryDialog} onCreate={handleCategoryCreate} onClose={() => { setOpenSubCategoryDialog(false) }} />
      <Stack minWidth={"100%"}>
        {category.status !== "P" && (
          <Alert severity="warning" sx={{ mb: 2 }}>
            This category is not reviewed yet. The information may not be accurate. You can help us by creating a new list under this category.
          </Alert>
        )}

        <Typography variant="h3" component={"h1"}>{category.name}</Typography>
        <Typography variant="body1" sx={{ mt: 0.5 }} color="text.secondary">
          <span dangerouslySetInnerHTML={{ __html: category.description }}></span>
        </Typography>

        <Grid container direction={"row"} spacing={0} sx={{ mt: 3 }} alignItems={"flex-end"}>
          <Grid item xs={12} md={9}>
            <Typography variant="h5" color="text.secondary" component={"h2"}>{t("subcategory.lists")}</Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            {lists.length > 0 && (
              <Typography sx={{ pb: 1, textAlign: "right" }} variant="body1" color="text.secondary">
                <Link color="text.secondary" href={"/create?category_id=" + category.id + "&category_name=" + encodeTitle(category.name)}>
                  {t("subcategory.create-new-list")} >>
                </Link>
              </Typography>
            )}
          </Grid>
        </Grid>
        <Divider sx={{ mb: 1 }} />

        {lists.length === 0 &&
          <Box display="flex" alignItems="center" sx={{ mt: 2, mb: 2 }}>
            <InfoIcon sx={{ mr: 1 }} /> {t("subcategory.no-lists")}&nbsp;
            <Link color="text.secondary" href={"/create?category_id=" + category.id + "&category_name=" + encodeTitle(category.name)}>
              {t("subcategory.create-new-list")} >>
            </Link>
          </Box>
        }


        {Object.keys(groupedLists).map((letter) => (
          <Grid container key={letter} sx={{ mb: 1 }} spacing={0}>
            <Grid item xs={12}>
              <Typography variant="body1"><i>{letter}</i></Typography>
            </Grid>
            {groupedLists[letter].map((list) => (
              <Grid item xs={12} sm={6} md={4} key={list.id}>
                <Typography variant="subtitle1">
                  • <Link href={"/list/" + encodeTitle(list.title)}>
                    {list.title}
                  </Link>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {/* {category.get_child.description} */}
                </Typography>
              </Grid>
            ))}
          </Grid>
        ))}

        <Grid container direction={"row"} spacing={0} alignItems={"flex-end"}>
          <Grid item xs={12} md={9}>
            <Typography variant="h5" sx={{ mt: 3 }} color="text.secondary" component={"h2"}>{t("subcategory.sub-categories")}</Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            {categories.length > 0 && (
              <Typography sx={{ pb: 1, textAlign: "right" }} variant="body1" color="text.secondary">
                <Link color="text.secondary" component={"button"} onClick={handleCreateCategoryClick}>
                  {t("subcategory.create-new-category")} >>
                </Link>
              </Typography>
            )}
          </Grid>
        </Grid>


        <Divider sx={{ mb: 1 }} />
        {categories.length === 0 &&

          <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
            <InfoIcon sx={{ mr: 1 }} /> {t("subcategory.no-sub-categories")}&nbsp;
            <Link component={"button"} color="text.secondary" onClick={handleCreateCategoryClick} >
              {t("subcategory.create-new-category")} >>
            </Link>
          </Box>
        }

        {Object.keys(groupedCategories).map((letter) => (
          <Grid container key={letter} sx={{ mb: 1 }} spacing={0}>
            <Grid item xs={12}>
              <Typography variant="body1"><i>{letter}</i></Typography>
            </Grid>
            {groupedCategories[letter].map((category) => (
              <Grid item xs={12} sm={6} md={4} key={category.get_child.id}>
                <Typography variant="subtitle1">
                  • <Link href={"/category/" + encodeTitle(category.get_child.name)}>
                    {category.get_child.name}
                  </Link>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {trim(category.get_child.description, 130)}
                </Typography>
              </Grid>
            ))}
          </Grid>
        ))}
      </Stack >
    </Container>

  );
}