import { Box } from '@mui/material';
import ListRenderer from 'src/components/list-renderer';
import ContentRenderer from './content-renderer';

export default function BulletListItem({ item }) {
  return (
    <Box>
      <ContentRenderer content={item.content} />
      <ListRenderer topLevel={false} itemID={item.id} document={item.children} />
    </Box>
  );
}
