import { Link, Stack, Typography, Box, Grid, Button, Divider, Container } from "@mui/material";
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { ListApi } from "src/apis/list"
import StringAvatar from "src/components/string-avatar";
import { formatDistance } from 'date-fns';
import { BsReply } from "react-icons/bs";
import { MdDeleteOutline } from "react-icons/md";
import { MdReportGmailerrorred } from "react-icons/md";
import { CommentApi } from "src/apis/comment";
import CommentInput from "src/components/comment-input";
import ConfirmDialog from "src/components/confirm-dialog";

export default function Discuss() {
  const params = useParams();
  const [comments, setComments] = useState(null);
  const [list, setList] = useState(null);
  const [reportedComments, setReportedComments] = useState([]);
  const [replyComment, setReplyComment] = useState(null);
  const [replyText, setReplyText] = useState('');
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [commentToDelete, setCommentToDelete] = useState(null);

  useEffect(() => {
    ListApi.get(params.listTitle).then((response) => {
      setList(response[0]);
    });

    ListApi.getComments(params.listTitle, params.commentId).then((response) => {
      // Sort comments by created_at date
      response.sort((a, b) => {
        return new Date(a.created_at) - new Date(b.created_at);
      });

      // Group comments by parent comment
      const groupedComments = {};
      response.forEach((comment) => {
        if (comment.parent == null) {
          groupedComments[comment.id] = [comment];
        } else {
          groupedComments[comment.parent].push(comment);
        }
      });

      setComments(groupedComments);
      setReportedComments(JSON.parse(localStorage.getItem('reports') || "[]"));

    });
  }, [params.listTitle, setComments, setList, params.commentId]);

  // const handleVersionsChange = (event) => {
  //   const versions = event.target.value;
  //   if (versions.length === 0 || versions[versions.length - 1] === -1) {
  //     setActiveVersions([-1]);
  //   } else {
  //     if (versions.includes(-1)) {
  //       versions.splice(versions.indexOf(-1), 1);
  //     }
  //     setActiveVersions(versions);
  //   }
  // }

  const handleUnReport = async (id) => {
    const reportedComments = JSON.parse(localStorage.getItem('reports') || "[]");
    const index = reportedComments.indexOf(id);
    if (index > -1) {
      reportedComments.splice(index, 1);
    }

    localStorage.setItem('reports', JSON.stringify(reportedComments));
    setReportedComments(reportedComments);

    CommentApi.report(id, false);
  }

  const handleReport = async (id) => {
    const reportedComments = JSON.parse(localStorage.getItem('reports') || "[]");
    reportedComments.push(id);
    if (reportedComments.length > 50) {
      reportedComments.shift();
    }

    localStorage.setItem('reports', JSON.stringify(reportedComments));

    setReportedComments(reportedComments);
    CommentApi.report(id, true);
  }

  const handleReplyClick = (comment) => {
    setReplyComment(comment.id);
  }

  const handleDeleteComment = () => {
    CommentApi.delete(commentToDelete).then(() => {
      window.location.reload();
    });
  }

  const handleSubmit = (parentCommentId, parentListId) => {
    CommentApi.post(parentCommentId, parentListId, replyText).then(() => {
      setReplyComment(null);
      setReplyText('');
      window.location.reload();
    });
  }

  const handleReplyTextChange = (value) => {
    setReplyText(value);
  }

  return (
    <Container>
      <ConfirmDialog open={confirmDialogOpen} onConfirm={handleDeleteComment} onClose={() => { setConfirmDialogOpen(false) }} message={"Are you sure you want to delete this comment?"} />
      <Stack minWidth={"100%"}>
        <Grid container spacing={1} sx={{ marginBottom: 1 }}>
          <Grid item md={8} xs={12}>

            <Typography color={"text.secondary"} variant="h4" component={"h4"}>Discuss:&nbsp;
              {!params.commentId && (
                <Link href={`/list/${params.listTitle}`}>
                  {list?.title}
                </Link>
              )}


            </Typography>
          </Grid>
          {/* <Grid item xs={4} textAlign={"right"}>
            <FormControl size="small">
              <InputLabel>Filter by version</InputLabel>
              <Select
                value={activeVersions}
                label="Filter by version"
                onChange={handleVersionsChange}
                multiple={true}
              >
                <MenuItem value={-1}>All versions</MenuItem>
                <MenuItem value={10}>Version #19</MenuItem>
                <MenuItem value={20}>Version #20</MenuItem>
                <MenuItem value={30}>Version #22</MenuItem>
              </Select>
            </FormControl>
          </Grid> */}
        </Grid>

        {Object.keys(comments || []).map((commentId) => (

          <Stack key={commentId} spacing={2} sx={{ marginTop: 2 }}>
            {comments[commentId][0].list_item_text && !params.commentId &&
              <>
                <Box sx={{ borderLeft: "solid 3px #ececec", padding: 0, margin: 0 }}>
                  <ul style={{ marginLeft: -10 }}>
                    <li>
                      <Typography variant="h6" sx={{ fontStyle: "italic" }}>"{comments[commentId][0].list_item_text}"</Typography>
                    </li>
                  </ul>
                </Box>
              </>
            }
            {
              comments[commentId].map((comment, index) => (
                <span key={comment.id}>
                  <Stack direction={"row"} spacing={1} sx={{ paddingLeft: index > 0 ? 5 : 0, }}>
                    <span style={{ paddingTop: 4 }}>
                      <StringAvatar username={comment.get_user} />
                    </span>
                    <Stack width={"100%"}>
                      <Stack direction={"row"} spacing={1}>
                        <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>{comment.get_user}</Typography>
                        {/* <Link variant="caption" color={"text.primary"} href={`/discuss/${params.listTitle}/${commentId}`}> */}
                        <Typography variant="caption" color={"text.secondary"}>
                          {formatDistance(new Date(comment.created_at), new Date(), { addSuffix: true })}
                        </Typography>
                        {/* </Link> */}
                      </Stack>

                      {comment.status === "A" &&
                        <Typography sx={{ mt: 0.5 }} variant="body1" key={comment.id}>
                          <span dangerouslySetInnerHTML={{ __html: comment.content }}></span>
                        </Typography>
                      }

                      {comment.status === "U" &&
                        <Typography sx={{ mt: 0.5, fontStyle: "italic" }} variant="body1" color="warning.main" key={comment.id}>[@{comment.get_user} deleted their comment.]</Typography>
                      }

                      {comment.status === "W" &&
                        <Typography sx={{ mt: 0.5, fontStyle: "italic" }} variant="body1" color="warning.main" key={comment.id}>[Comment removed by Wikilist.]</Typography>
                      }

                      {comment.status === "A" && (
                        <Stack direction={"row"} spacing={1} sx={{ mt: 0.5 }}>

                          <Link variant="caption" component={"button"} onClick={() => { handleReplyClick(comment) }} color={"text.secondary"} href={`/discuss/${params.listTitle}/${comment.id}`}>
                            <BsReply /><span style={{ marginLeft: 3 }}>Reply</span>
                          </Link>

                          {comment.get_user === localStorage.getItem('username') &&
                            <Link variant="caption" component={"button"} onClick={() => { setCommentToDelete(comment.id); setConfirmDialogOpen(true) }} color={"text.secondary"} href={`/discuss/${params.listTitle}/${comment.id}`}>
                              <MdDeleteOutline /><span style={{ marginLeft: 3 }}>Delete</span>
                            </Link>
                          }
                          {reportedComments.includes(comment.id) ? (
                            <Link variant="caption" component={"button"} color={"error.main"} onClick={() => { handleUnReport(comment.id) }} >
                              <MdReportGmailerrorred /><span style={{ marginLeft: 3 }}>Reported!</span>
                            </Link>
                          ) : (
                            <Link variant="caption" component={"button"} onClick={() => { handleReport(comment.id) }} color={"text.secondary"}>
                              <MdReportGmailerrorred /><span style={{ marginLeft: 3 }}>Report</span>
                            </Link>
                          )}
                        </Stack>
                      )}
                      {replyComment === comment.id &&
                        <Stack sx={{ marginTop: 1 }}>
                          <CommentInput defaultValue={`@${comment.get_user}&nbsp;`} onChange={handleReplyTextChange} />
                          <Stack direction={"row"} spacing={1} sx={{ marginTop: 1 }}>
                            <Button size="small" variant={"outlined"} onClick={() => { handleSubmit(commentId, list.id) }} >Submit</Button>
                            <Button size="small" variant={"outlined"} onClick={() => { setReplyComment(null) }}>Cancel</Button>
                          </Stack>
                        </Stack>
                      }

                    </Stack>
                  </Stack>

                </span>
              ))
            }
          </Stack>
        ))}

        {replyComment === null &&
          <Stack sx={{ mt: 3 }} spacing={1.5}>
            <Divider />
            <Typography variant="body1">
              You can start a new discussion thread about the list here. If you wish to talk about a specific list item, please <Link href={`/list/${list?.title}`}>select an item from the list</Link>.
            </Typography>
            <CommentInput defaultValue={``} onChange={handleReplyTextChange} />
            <Stack direction={"row"} spacing={1}>
              <Button size="small" variant={"outlined"} onClick={() => { handleSubmit(null, list.id) }} >Submit</Button>
            </Stack>
          </Stack>
        }
      </Stack >
    </Container>
  )
}