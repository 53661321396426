import { api } from "src/apis/configs/axios"
import { defineCancelApiObject } from "src/apis/configs/axiosUtils"


export const CommentApi = {
  report: async function (id, report = true, cancel = false) {
    const response = await api.request({
      url: `/api/comments/${id}/report/`,
      method: "POST",
      data: {
        id: id,
        report: report,
      },
      // retrieving the signal value by using the property name
      signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
    }).catch((error) => {
      return { data: { error: "error reporting" } }
    });
    return response.data
  },

  post: async function (parentCommentId, parentListId, text, cancel = false) {
    const response = await api.request({
      url: `/api/comments/${parentCommentId}/post/`,
      method: "POST",
      data: {
        parent_comment_id: parentCommentId,
        parent_list_id: parentListId,
        text: text,
      },
      signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
    }).catch((error) => {
      return { data: { error: "error replying" } }
    });
    return response.data
  },

  delete: async function (commentId, cancel = false) {
    const response = await api.request({
      url: `/api/comments/${commentId}/delete/`,
      method: "POST",
      data: {
        comment_id: commentId,
      },
      signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
    }).catch((error) => {
      return { data: { error: "error deleting" } }
    });
    return response.data
  }

};

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(CommentApi)