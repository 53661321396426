import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { debounce } from '@mui/material/utils';
import { ListApi } from 'src/apis/list';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { styled } from '@mui/system';
import { createFilterOptions } from '@mui/material/Autocomplete';

const filter = createFilterOptions();

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  paddingLeft: '8px',
  fontStyle: "italic",
  fontWeight: 700,
  fontSize: "0.8rem",
  backgroundColor: "#ddd",

}));

const GroupItems = styled('ul')({
  padding: 0,
});

const ListSelector = ({ autoFocus = false, listOnly = false, enableNewList = false, allLanguages = false, searchText, onSelect }) => {
  const [options, setOptions] = React.useState([]);
  const [inputValue, setInputValue] = React.useState('');

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        ListApi.search(request.input, listOnly, allLanguages).then((response) => {
          const results = response;
          callback(results);
        });
      }, 400),
    [listOnly, allLanguages],
  );


  React.useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        setOptions(results);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);


  return (
    <Autocomplete
      id="listInput"
      fullWidth
      options={options}
      freeSolo
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      groupBy={(option) => option.type}

      renderGroup={(params) => (
        <span key={params.key}>
          <li>
            <GroupHeader>{params.group === "list" ? "Lists" : (params.group === "add" ? "No list found!" : "Categories")}</GroupHeader>
            <GroupItems>{params.children}</GroupItems>
          </li>
        </span>
      )}

      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        if (params.inputValue !== '' && filtered.length === 0 && enableNewList) {
          filtered.push({
            type: "add",
            inputValue: params.inputValue,
            title: `Create "${params.inputValue}" list?`,
          });
        }

        return filtered;
      }}

      getOptionLabel={(option) => {
        return option.title || "";
      }}

      value={""}

      onChange={(event, newList) => {
        if (!newList) {
          return;

        }
        onSelect(newList);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} autoFocus={autoFocus} size="small" placeholder={searchText ? searchText : "Search for a list"} variant='outlined' fullWidth />
      )}
      renderOption={(props, option, { inputValue }) => {
        const matches = match(option.title, inputValue, { insideWords: true });
        const parts = parse(option.title, matches);

        return (
          <li {...props} key={uuidv4()}>
            <div>
              {allLanguages && option.language && <span style={{ fontStyle: "italic", fontSize: "0.8rem" }}>[{option.language}]&nbsp;</span>}
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                    backgroundColor: part.highlight ? '#E77051' : 'inherit',
                    color: part.highlight ? 'white' : 'inherit',
                  }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          </li>
        );
      }}

    />
  )
}

export default ListSelector;