import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ListApi } from 'src/apis/list';
import { useState } from 'react';
import { Container, Divider, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Item from '@mui/material/Grid';
import Link from '@mui/material/Link';
import ListRenderer from 'src/components/list-renderer';
import HomePageListRenderer from 'src/components/homepage-list-renderer';
import { GrDomain } from "react-icons/gr";
import Button from '@mui/material/Button';
import { isAdmin, isListerian } from 'src/tools/user';
import { decodeTitle, encodeTitle } from 'src/tools/title-encoder';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import { countCitesAndItems } from 'src/tools/list';
import HelmetTag from 'src/components/helmet-tag';
import ReactGA from 'react-ga4';
import { Trans, useTranslation } from 'react-i18next';


export default function ListView() {
  const { listTitle } = useParams();
  const [content, setContent] = useState(null);
  const [listId, setListId] = useState(null);
  const [list, setList] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [listStats, setListStats] = useState(null);

  const version = new URLSearchParams(window.location.search).get('version');
  const [activeVersion, setActiveVersion] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (listTitle) {
      setIsLoading(true);

      ListApi.get(listTitle, version).then((response) => {
        setIsLoading(false);
        if (response.error) {
          alert(response.error);
          return;
        }

        if (response.length === 0) {
          return;
        }

        const list = response[0];
        setList(list);
        setListId(list.id);

        const content = JSON.parse(list.content);
        setListStats(countCitesAndItems(content));

        setContent(content);
        setActiveVersion(list.get_active_version);
        document.title = list.title + ' - Wikilist';
      }
      )

    };
  }, [listTitle, version]);


  const handleAdminPublish = () => {
    if (!window.confirm("Are you sure you want to publish this list?")) {
      return;
    }

    let version_number = version;

    if (version_number === null) {
      version_number = list.get_active_version.version_number;
    }

    ListApi.publish(listId, version_number).then((response) => {
      if (response.error) {
        alert(response.error);
        return;
      }
      window.location.href = "/list/" + encodeTitle(listTitle);
    });
  }

  const getKeywords = () => {
    let keywords = ""
    if (list) {
      keywords = list.title + ", " +
        t("list.title-keywords", { title: listTitle }) + ", " +
        list.get_categories.map((category) => category.name).join(", ")
    }
    return keywords;
  }

  const getDescription = () => {
    let description = "";
    if (content) {
      // Get the first 3 list items' children's content joined with comma
      // content is an array of items
      let firstItems = [];
      for (let i = 0; i < content.length; i++) {
        if (content[i].type === "numberedListItem") {
          let childContent = null;
          if (content[i].children.length > 0) {
            // Find the first child thats type = text
            const child = content[i].children.find(child => child.type === "text" || child.type === "bulletListItem");
            childContent = child?.content;
          } else {
            childContent = content[i].content;
          }
          if (childContent) {
            // walk through child content array and find text types
            for (let j = 0; j < childContent.length; j++) {
              if (childContent[j].type === "text") {
                firstItems.push(childContent[j].text);
              }
            }
          }

          firstItems.push();
        }
        if (firstItems.length === 3) {
          break;
        }
      }
      description = firstItems.join("");

    }
    return description;
  }

  const metaKeywords = getKeywords();
  const metaDescription = getDescription();
  const metaTitle = list?.title + " - Wikilist";

  useEffect(() => {
    if (list) {
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: list.title });
    }
  }, [list]);

  return (
    <>
      <HelmetTag metaTitle={metaTitle} metaKeywords={metaKeywords} metaDescription={metaDescription} />
      <Container id="list_view_container"
        sx={{
          minWidth: "100%",
          pl: { xs: 0, sm: 1 },
          pr: { xs: 0, sm: 1 },
        }}
      >
        {!isLoading && !list &&
          // Show users that this list doesnt exist and encourage them to create it with a good message
          <Stack sx={{ p: 2 }} spacing={2}>
            <Typography variant="h4" color="text.primary">
              <Trans i18nKey="list.not-exists" values={{ title: decodeTitle(listTitle) }} />
            </Typography>
            <Typography variant="body1" color="text.secondary">
              <Trans i18nKey="list.you-can-create" />
            </Typography>
            <Link href={"/create/?title=" + listTitle}>
              <Button variant="contained" color="primary">
                <Trans i18nKey="list.create-this-list" />
              </Button>
            </Link>

          </Stack>

        }
        {list &&
          <Grid container spacing={1.5}>

            <Grid item xs={12} md={9}>
              {list && list?.status !== "P" && (
                <Alert severity="warning" sx={{ mb: 2 }}>
                  This list is not reviewed yet. You can <Link href={`/edit/${encodeTitle(listTitle)}`}>contribute</Link> to get it published.
                </Alert>
              )}

              {listStats && parseFloat(listStats.cites) < parseFloat(listStats.listItems) * 0.25 && (version === null || version.toString() === activeVersion?.version_number.toString()) && (
                <>
                  {
                    listStats.cites === 0 && (
                      <Alert severity="warning" sx={{ mb: 2 }}>
                        <Trans i18nKey="list.no-citation"
                          components={{
                            link1: <Link href={t("list.citation_link")} />,
                            link2: <Link href={`/edit/${listTitle}`} />
                          }}
                        ></Trans>
                      </Alert>
                    )
                  }
                  {/* {listStats.cites > 0 && (
                  <Alert severity="warning" sx={{ mb: 2 }}>
                    <Trans i18nKey="list.low-citations"
                      components={{
                        link1: <Link href={t("list.citation_link")} />,
                        link2: <Link href={`/edit/${listTitle}`} />
                      }}
                      values={{ citesCount: listStats.cites, itemsCount: listStats.listItems }}
                    ></Trans>
                    </Alert>
                  )} */}
                </>
              )}




              {list && version && version.toString() !== activeVersion?.version_number.toString() && (
                <Alert severity="warning" sx={{ mb: 2 }}>
                  You are viewing a non-active version of this list. <Link href={`/list/${listTitle}`}>View the active version</Link>.
                </Alert>
              )}

              <Item>
                <Typography sx={{ mb: 1, px: { xs: 2, sm: 0 } }} component="h1" variant="h4" color="text.primary">
                  {!version && list?.title}

                  {version &&
                    <Stack direction={"row"} spacing={1}>
                      <Box>
                        {list?.title}
                      </Box>
                      <Link href={`/revisions/${listTitle}`}>
                        Version {version}
                      </Link>
                    </Stack>
                  }
                </Typography>


                {content && list?.homepage &&
                  <HomePageListRenderer isTopLevel={true} document={content} />
                }


                {content && !list?.homepage &&
                  <ListRenderer document={content} />
                }
              </Item>
            </Grid>
            <Grid item xs={0} md={3}>
              <Item sx={{ px: { xs: 2, sm: 0 } }}>
                <Stack spacing={1}>
                  <Typography variant="body2" color="text.secondary">
                    {t("list.you-can-contribute")}
                  </Typography>
                  <Stack spacing={1} direction={"row"}>
                    <Link color="secondary" href={"/edit/" + listTitle + "/" + (version ? "?version=" + version : "")}>{t("list.Edit")}</Link>
                    <Box>•</Box>
                    <Link color="secondary" href={"/discuss/" + listTitle}>{t("list.Discuss")}</Link>
                    <Box>•</Box>
                    <Link color="secondary" href={"/revisions/" + listTitle}>{t("list.Revisions")}</Link>
                  </Stack>
                  {list.get_translations.length > 0 &&
                    <Stack spacing={1}>
                      <Divider>
                        <Typography variant="caption" color="text.secondary">
                          {t("list.Translations")}
                        </Typography>
                      </Divider>
                      <Typography variant="caption" color="text.secondary">
                        {t("list.we-have-translations")}
                      </Typography>
                      {list.get_translations.map((translation, index) => (
                        <Stack key={translation.title}>
                          <Box>
                            • <Link href={"https://" + translation.language + ".wikilist.co/list/" + encodeTitle(translation.title)}>{translation.title}</Link>
                          </Box>
                          <Typography sx={{ ml: "10px" }} variant="caption" color="text.secondary">
                            {translation.language === "en" ? "English" : "Turkish"}
                          </Typography>
                        </Stack>

                      ))}
                    </Stack>

                  }
                  <Stack spacing={0.5}>
                    <Divider>
                      <Typography variant="caption" color="text.secondary">
                        {t("list.categories")}
                      </Typography>
                    </Divider>
                    {list?.get_categories.map((category) => (
                      <Box key={category.id} >
                        • <Link href={"/category/" + encodeTitle(category.name)}>{category.name}</Link>
                      </Box>
                    ))}
                  </Stack>

                  {(isListerian() || isAdmin()) && (
                    <>
                      <Divider sx={{ m: 1 }}>
                        <Typography variant="caption" color="text.secondary">
                          Admin tools
                        </Typography>
                      </Divider>
                      <Stack direction={"row"} spacing={2}>
                        {(list?.status !== "P" ||
                          (version && version.toString() !== activeVersion?.version_number.toString())
                        )
                          && (
                            <Button variant="outlined" color="primary" size='small' onClick={handleAdminPublish}>Publish</Button>
                          )}
                      </Stack>
                    </>
                  )}
                  {list?.homepage &&
                    <>
                      <Divider sx={{ m: 1 }} />
                      <Typography sx={{ mt: 2 }} variant="body2" color="text.secondary">
                        <GrDomain /> This is the homepage of Wikilist. It has a custom style, that's why it looks different from other lists.
                      </Typography>
                    </>
                  }
                </Stack>


              </Item>
            </Grid>
          </Grid>
        }


      </Container >
    </>

  );


}