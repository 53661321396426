import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';

export default function Footer() {
  const { t } = useTranslation();
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 2, sm: 4 },
        py: { xs: 2, sm: 4 },
        textAlign: { sm: 'center', md: 'left' },
      }}
    >

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: { xs: 1, sm: 1 },
          width: '100%',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        <Stack>
          <Typography variant="body2">
            <Trans i18nKey="footer.license"
              components={{ link1: <Link color="text.secondary" href={"/list/" + t("footer.license_link")} /> }}
            ></Trans>

            <Trans i18nKey="footer.term"
              components={{
                link1: <Link color="text.secondary" href={"/list/" + t("footer.terms_link")} />,
                link2: <Link color="text.secondary" href={"/list/" + t("footer.privacy_link")} />
              }}
            ></Trans>

          </Typography>
        </Stack>

      </Box>
    </Container>
  );
}