import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import InputAdornment from '@mui/material/InputAdornment';

import { useBoolean } from 'src/hooks/use-boolean';
import { Alert } from '@mui/material';

import Iconify from 'src/components/iconify';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { AuthApi } from 'src/apis/auth';
import { useState } from 'react';
import { Container, Box } from '@mui/material';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';

// ----------------------------------------------------------------------

export default function LoginCoverView() {
  const passwordShow = useBoolean();
  const [error, setError] = useState("");

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: "Login" });
  }, []);


  const LoginSchema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    password: Yup.string()
      .required('Password is required')
      .min(6, 'Password should be of minimum 6 characters length'),
  });

  const defaultValues = {
    username: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    AuthApi.getToken(data.username, data.password).then((response) => {
      if (response.error) {
        setError(response.error);
      } else if (!response.access) {
        setError("Invalid credentials");
      } else {
        reset();
        // Save the token in the local storage
        localStorage.setItem("token", response.access);
        localStorage.setItem("refresh", response.refresh);
        localStorage.removeItem("user");
        // Redirect to the home page
        window.location.href = "/home";

      }
    }
    ).catch((error) => {
      // Set the error message in the form
      setError("Try again!");
    })

  });

  const renderError = (
    error &&
    <Alert variant="filled" severity="error">{error}</Alert>
  );

  const renderHead = (
    <Stack
      sx={{
        pb: 5,
        pt: { xs: 1, md: 2 },
        textAlign: { xs: 'center', md: 'left' },
      }}
    >
      <Typography variant="h3" paragraph>
        <Trans>Login</Trans>
      </Typography>

      {/* Render error if exists */}
      {renderError}



      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        <Trans>Don’t have an account?</Trans>
        <Link href={"/register"} variant="subtitle2" color="primary" sx={{ ml: 1 }}>
          <Trans>Get started</Trans>
        </Link>
      </Typography>
    </Stack>
  );

  const renderForm = (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Stack spacing={2.5} alignItems="flex-end">
        <RHFTextField name="username" placeholder={t("register.username")} />

        <RHFTextField
          name="password"
          placeholder={t("register.password")}
          type={passwordShow.value ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={passwordShow.onToggle} edge="end">
                  <Iconify icon={passwordShow.value ? 'carbon:view' : 'carbon:view-off'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />


        <LoadingButton
          fullWidth
          color="primary"
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          <Trans>Sign in</Trans>
        </LoadingButton>
      </Stack>
    </FormProvider>
  );

  return (
    <>

      <Container
        id="testimonials"
        sx={{
          pt: { xs: 4, sm: 12 },
          pb: { xs: 8, sm: 16 },
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          {renderHead}

          {renderForm}
        </Box>
      </Container>

    </>
  );
}
