import React from 'react';

export default function ImageRenderer({ topLevel, itemID, item }) {
  return (
    <div style={{
      padding: topLevel ? "0" : " 0.6rem 0 0 1.3rem",
    }} >
      <img
        alt={item.props.name} src={item.props.url}
        style={{
          width: "100%",
          borderRadius: topLevel ? "0" : "5px",
        }}
      /></div>
  )
};
