import NumberedListItem from './numbered-list-item';
import BulletListItem from './bullet-list-item';
import ImageRenderer from './image-renderer';

export default function ListRenderer({ document, topLevel = true }) {
  const renderListItems = (items, startIndex) => {
    const listItems = [];
    let index = startIndex;

    while (index < items.length && items[index].type === 'numberedListItem') {
      listItems.push(
        <li style={{ marginBottom: 7 }} key={index}>
          <NumberedListItem key={index} item={items[index]} />
        </li>
      );
      index++;
    }

    return { listItems, nextIndex: index };
  };

  const renderBulletListItems = (items, startIndex) => {
    const listItems = [];
    let index = startIndex;

    while (index < items.length && items[index].type === 'bulletListItem') {
      listItems.push(
        <li style={{ marginTop: 7, listStyleType: 'disc' }} key={index}>
          <BulletListItem key={index} item={items[index]} />
        </li>
      );
      index++;
    }

    return { listItems, nextIndex: index };
  }

  const renderDocument = (items) => {
    const renderedElements = [];
    let index = 0;

    if (!items) {
      return null;
    }

    while (index < items.length) {
      const item = items[index];

      if (item.type === 'numberedListItem') {
        const { listItems, nextIndex } = renderListItems(items, index);
        renderedElements.push(
          <ol style={{ paddingLeft: "35px", paddingRight: "15px" }} key={item.id} > {listItems}</ol >
        );
        index = nextIndex;
      } else if (item.type === 'bulletListItem') {
        const { listItems, nextIndex } = renderBulletListItems(items, index);
        renderedElements.push(
          <ul style={{ marginBottom: 20 }} key={item.id}>{listItems}</ul>
        );
        index = nextIndex;
      } else if (item.type === 'image') {
        renderedElements.push(<ImageRenderer topLevel={topLevel} key={item.id} item={item} />);
        index++;
      } else {
        if (item.type === 'text') {
          renderedElements.push(<p key={item.id}>{item.text}</p>);
        }
        index++;
      }
    }

    return renderedElements;
  };

  return renderDocument(document);




}