import * as React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import ToggleColorMode from 'src/components/toggle-color-mode';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import WbSunnyRoundedIcon from '@mui/icons-material/WbSunnyRounded';
import ModeNightRoundedIcon from '@mui/icons-material/ModeNightRounded';
import ListSelector from '../list-selector';
import { AuthApi } from 'src/apis/auth';
import StringAvatar from '../string-avatar';
import { isAdmin, isListerian, isNormalUser } from 'src/tools/user';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { encodeTitle } from 'src/tools/title-encoder';


const logoStyle = {
  width: '100px',
  height: 'auto',
  cursor: 'pointer',
  marginRight: '15px',
};


function AppAppBar({ mode, toggleColorMode }) {
  const [open, setOpen] = React.useState(false);
  const [user, setUser] = React.useState(JSON.parse(localStorage.getItem('user') || null));
  const token = localStorage.getItem('token');
  const { t } = useTranslation();

  React.useEffect(() => {
    if (token && !user) {
      AuthApi.getMyProfile().then((response) => {
        if (response.error) {
          localStorage.removeItem("token");
          localStorage.removeItem("refresh");
          localStorage.removeItem("username");
        } else {
          localStorage.setItem("user", JSON.stringify(response));
          setUser(response);
        }
      });

    }
  }, [token, user]);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };



  const menu = [
    { label: t("bar.Contribute"), href: "/create" },
    { label: t("bar.Categories"), href: "/categories" },
    { label: t("bar.Our vision"), href: t("bar.our_vision_link") },
    { label: t("bar.Contact us"), href: t("bar.contact_us_link") },
  ];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refresh");
    localStorage.removeItem("user");

    window.location.href = "/login";
  }

  const handleListSelect = (searchItem) => {
    if (searchItem) {
      if (searchItem.type === "list") {
        window.location.href = `/list/${encodeTitle(searchItem.title)}`;
      } else if (searchItem.type === "category") {
        window.location.href = `/category/${encodeTitle(searchItem.title)}`;
      } else {
        const title = searchItem.inputValue;
        window.location.href = `/create/?title=${title}`;
      }
    }
  }

  return (
    <div>
      <AppBar
        position="relative"
        sx={{
          boxShadow: 0,
          bgcolor: 'transparent',
          backgroundImage: 'none',
          mt: 0,
        }}
      >
        <Container sx={{ px: 0 }} maxWidth="lg">
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexShrink: 0,
              bgcolor:
                theme.palette.mode === 'light'
                  ? 'rgba(255, 255, 255, 0.4)'
                  : 'rgba(0, 0, 0, 0.4)',
              maxHeight: 30,
              border: 0,
              borderBottom: '1px solid',
              borderColor: 'divider',

            })}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                ml: '-18px',
                px: 2,
              }}
            >
              <Link sx={{ padding: 0, marginTop: 1 }} href="/home"><img
                src={
                  '/wikilist_logo.png'
                }
                style={logoStyle}
                alt="logo of sitemark"
              /></Link>
              <Box sx={{ display: { xs: 'none', md: 'flex', marginTop: "4px" }, width: { xs: "100%", md: "45%" }, mr: 1 }}>
                <ListSelector enableNewList searchText={t("Search for lists")} onSelect={handleListSelect} />
              </Box>
              <Box sx={{ display: { xs: 'none', md: 'flex', marginTop: "4px" } }}>
                {menu.map((item, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => window.location.href = item.href}
                    sx={{ py: '6px', px: '12px' }}
                  >
                    <Typography variant="body2" color="text.primary">
                      {item.label}
                    </Typography>
                  </MenuItem>
                ))
                }
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                gap: 0.5,
                alignItems: 'center',
                marginTop: "6px",
              }}
            >

              {!user &&
                <>
                  <Button
                    color="primary"
                    variant="text"
                    size="small"
                    component="a"
                    href="/login"
                    target="_self"
                  >
                    <Trans>Sign in</Trans>
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    component="a"
                    href="/register"
                    target="_self"
                  >
                    <Trans>Sign up</Trans>
                  </Button>
                </>
              }
              {user &&
                <>
                  <Tooltip title="Account settings">
                    <IconButton
                      onClick={handleClick}
                      size="small"
                      sx={{ ml: 2 }}
                      aria-controls={menuOpen ? 'account-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={menuOpen ? 'true' : undefined}
                    >
                      <StringAvatar username={user.get_user.username} />

                    </IconButton>
                  </Tooltip>


                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={menuOpen}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        '&::before': {
                          content: '""',
                          display: 'block',
                          position: 'absolute',
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: 'background.paper',
                          transform: 'translateY(-50%) rotate(45deg)',
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  >
                    <Box sx={{ pl: 2, mb: 1 }}>
                      <Typography variant="body2" color="text.primary">
                        {user.get_user.username}
                      </Typography>
                      {!isNormalUser() && (
                        <Typography variant="caption" color="text.secondary">
                          {isAdmin() && t("Admin")}
                          {isListerian() && t("Listerian")}

                        </Typography>
                      )}
                    </Box>
                    <Divider sx={{ mb: 1 }} />
                    <MenuItem onClick={() => { toggleColorMode(); handleClose(); }}>
                      <ListItemIcon>
                        {mode === 'dark' ? (
                          <WbSunnyRoundedIcon fontSize="small" />
                        ) : (
                          <ModeNightRoundedIcon fontSize="small" />
                        )}
                      </ListItemIcon>
                      {mode === 'dark' ? (
                        <Typography variant="body2"><Trans>Light mode</Trans></Typography>
                      ) : (
                        <Typography variant="body2"><Trans>Dark mode</Trans></Typography>
                      )}
                    </MenuItem>
                    {isAdmin() &&
                      <Box>
                        <Divider>
                          <Typography variant="body2">Admin</Typography>
                        </Divider>
                        <MenuItem onClick={() => window.location.href = "/manage/categories"}>
                          Categories
                        </MenuItem>
                        <MenuItem onClick={() => window.location.href = "/manage/category_relations"}>
                          Category Relations
                        </MenuItem>
                      </Box>
                    }
                    <Divider />
                    <MenuItem onClick={handleLogout}>
                      <ListItemIcon>
                        <Logout fontSize="small" />
                      </ListItemIcon>
                      <Trans>Logout</Trans>
                    </MenuItem>
                  </Menu>
                </>
              }
            </Box>
            <Box sx={{ display: { sm: '', md: 'none' } }}>
              <Button
                variant="text"
                color="secondary"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{ minWidth: '30px', p: '4px' }}
              >
                <MenuIcon />
              </Button>
              <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                <Box
                  sx={{
                    minWidth: '60dvw',
                    p: 2,
                    backgroundColor: 'background.paper',
                    flexGrow: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'start',
                      mb: 2,
                    }}>
                    <ListSelector enableNewList searchText={t("Short Search for lists")} onSelect={handleListSelect} />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'end',
                      flexGrow: 1,
                    }}
                  >
                    <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />
                  </Box>
                  {menu.map((item, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => window.location.href = item.href}
                    >
                      {item.label}
                    </MenuItem>
                  ))}

                  <Divider />
                  {!user &&
                    <>
                      <MenuItem>
                        <Button
                          color="primary"
                          variant="contained"
                          component="a"
                          href="/register"
                          sx={{ width: '100%' }}
                        >
                          <Trans>Sign up</Trans>
                        </Button>
                      </MenuItem>
                      <MenuItem>
                        <Button
                          color="primary"
                          variant="outlined"
                          component="a"
                          href="/login"
                          sx={{ width: '100%' }}
                        >
                          <Trans>Sign in</Trans>
                        </Button>
                      </MenuItem>
                    </>
                  }
                  {user &&
                    <MenuItem>
                      <Button
                        color="primary"
                        variant="outlined"
                        component="a"
                        sx={{ width: '100%' }}
                        onClick={handleLogout}
                      >
                        <Trans>Logout</Trans>
                      </Button>
                    </MenuItem>
                  }
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

AppAppBar.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
};

export default AppAppBar;