import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, DialogContentText, Divider, Link } from '@mui/material';
import { encodeTitle } from 'src/tools/title-encoder';
const CategoryAddedDialog = ({ open, addedCategory, onClose }) => {

  return (
    <Dialog open={open} maxWidth="sm" fullWidth={true} onClose={onClose}>
      <DialogTitle>Category "{addedCategory?.name}" added successfully!
        <Divider />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Sub category added successfully. It will be publicly visible in the categories page after approval.<br /><br />
          Meanwhile you can view and add lists to this <Link href={`/category/${encodeTitle(addedCategory?.name)}`}>sub category</Link>. Also it's available in the search.
        </DialogContentText>

      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CategoryAddedDialog;