import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { debounce } from '@mui/material/utils';
import { CategoryApi } from 'src/apis/category';
import { v4 as uuidv4 } from 'uuid';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { Chip } from '@mui/material';
import NewCategoryDialog from '../new-category-dialog';

const filter = createFilterOptions();

export default function CategoryInput({ categories, onCategoriesChange }) {
  const [value, setValue] = React.useState(categories);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const [newCategory, setNewCategory] = React.useState(null);

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        CategoryApi.search(request.input).then((response) => {
          const results = response;
          callback(results);
        });
      }, 400),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {

        let newOptions = value;

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        // clear duplicates
        newOptions = newOptions.filter((option, index, self) =>
          index === self.findIndex((t) => (
            t.id === option.id
          ))
        );

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const handleAddCategory = (name, description, parentCategories) => {
    const newCategory = {
      id: uuidv4(),
      name: name,
      description: description,
      parentCategories: parentCategories
    }
    const newValue = [...value, newCategory];
    setValue(newValue);
    onCategoriesChange(newValue);
    setNewCategory(null);
  }

  return (
    <>
      <NewCategoryDialog inputValue={newCategory} onClose={() => { setNewCategory(null) }} onCategoryAdd={handleAddCategory} />
      <Autocomplete
        id="categoryInput"
        fullWidth
        multiple
        freeSolo
        selectOnFocus
        clearOnBlur
        options={options}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              name: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}

        getOptionLabel={(option) => {
          // for example value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.name;
        }}

        value={categories}

        onChange={(event, newCategories) => {
          // Loop through new categories, find the one with inputValue
          const newCategory = newCategories.find((category) => category.inputValue);
          if (newCategory) {
            // Remove newCategory from newCategories
            newCategories = newCategories.filter((category) => !category.inputValue);
            setNewCategory(newCategory.inputValue);
          }


          setValue(newCategories);
          onCategoriesChange(newCategories);

        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField {...params} placeholder="Add categories" variant='outlined' fullWidth />
        )}
        renderOption={(props, option) => (
          <li {...props} key={uuidv4()}>
            {option.name}
          </li>
        )}
        renderTags={(tagValue, getTagProps) => {
          return tagValue.map((option, index) => (
            <Chip {...getTagProps({ index })} key={uuidv4()} label={option.name} />
          ))
        }}

      />
    </>

  );
}
