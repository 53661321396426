import { Box } from '@mui/material';
import HomePageListRenderer from '../homepage-list-renderer';
import ListRenderer from 'src/components/list-renderer';
import ContentRenderer from './content-renderer';
import { decodeTitle } from 'src/tools/title-encoder';
import { useState, useEffect } from 'react';
import { ListApi } from 'src/apis/list';
import Link from '@mui/material/Link';
import { encodeTitle } from 'src/tools/title-encoder';
import Stack from '@mui/material/Stack';


export default function BulletListItem({ item }) {
  const [doc, setDoc] = useState(item.children);
  const [listTitle, setListTitle] = useState('');
  const isChildListLink = item.content.length === 1 && item.content[0].type === 'link';


  useEffect(() => {
    if (!isChildListLink) {
      return
    }
    let title = item.content[0].href;
    title = title.split('/')[title.split('/').length - 1]
    title = decodeTitle(title);
    setListTitle(title);

    ListApi.get(title).then((response) => {
      const list = response[0];
      const listContent = JSON.parse(list.content);
      setDoc(listContent);
    });

  }, [item, listTitle, isChildListLink]);

  return (
    <Box>
      {isChildListLink &&
        (
          <>
            <Box sx={{ height: "440px", overflow: "hidden" }} >
              <ContentRenderer content={item.content} />
              <ListRenderer itemID={item.id} document={doc} />
            </Box>
            <Stack sx={{ marginTop: 2 }} direction="row" spacing={1} justifyContent="flex-end">
              <Link variant="body2" href={"/list/" + encodeTitle(listTitle)}>continue reading</Link>
              <Box>•</Box>
              <Link variant="body2" href={"/edit/" + encodeTitle(listTitle)}>contribute</Link>
              <Box>•</Box>
              <Link variant="body2" href={"/discuss/" + encodeTitle(listTitle)}>discuss</Link>
            </Stack>
          </>
        )
      }
      {!isChildListLink &&
        <>
          <ContentRenderer content={item.content} />
          <HomePageListRenderer itemID={item.id} document={doc} />
        </>
      }
    </Box >
  );
}
